'use strict';

var page = require('./page');

var isTouchMoved = false;
var isCurrentTargetSwatch = false;

function initializeEvents() {
	$(document).off('click.Swatch.Menu.Close').on('click.Swatch.Menu.Close', function (event) {
		var $currentTarget = $(event.currentTarget);
		
		if ($currentTarget.closest('.slide-down-swatch').length == 0) {
			$('.slide-down-swatch ul').slideUp();
		}
	});
	
	$(document).off('touchstart.Swatch.Menu.Close').on('touchstart.Swatch.Menu.Close', function (event) {
		var $currentTarget = $(event.currentTarget);
		
		isTouchMoved = false;
		isCurrentTargetSwatch = $currentTarget.closest('.slide-down-swatch').length > 0;
	});
	
	$(document).off('touchmove.Swatch.Menu.Close').on('touchmove.Swatch.Menu.Close', function (event) {
		isTouchMoved = true;
	});
	
	$(document).off('touchend.Swatch.Menu.Close').on('touchend.Swatch.Menu.Close', function (event) {
		if (!isCurrentTargetSwatch && !isTouchMoved) {
			$('.slide-down-swatch ul').slideUp();
		}
	});
	
	$('.swatches.size > li > a.slide-down').on('click', function(event) {
		event.preventDefault();
		event.stopPropagation();
		
		$(this)
		.next('ul')
		.stop()
		.slideToggle()
		.parent()
		.siblings()
		.children('ul')
		.slideUp()
		.prev('.slide-down');
	});

	// Default selected value as slide down
	$('.swatches.size > li > a.slide-down')
	.next('ul')
	.find('.current')
	.closest('ul')
	.prev('.slide-down')
	.addClass('current')
	.closest('li').addClass('active');

	$('.refinements .swatches.size > li.active > ul').slideDown();

	$('.swatches > li.slide-down-swatch > ul').off('click');

	// Remove empty tiles 
	$('.swatches.size > li > a.slide-down').next('ul').each(function() {
		if ($(this).find('li').length === 0) {
			$(this).closest('li').remove();
		}
	});
}

function parseSizeValue(sizeValue, parser) {

	var matches = parser.exec(sizeValue.trim());

	var columnsMatchIndex = page.columnsMatchIndex,
	rowsMatchIndex = page.rowsMatchIndex;

	var maxMatchIndex = Math.max(columnsMatchIndex, rowsMatchIndex);

	// If all groups were matched, proceed with parsing. Otherwise, ignore this value
	if (matches && matches.length > maxMatchIndex && matches[columnsMatchIndex] && matches[rowsMatchIndex]) {
		var column = matches[columnsMatchIndex].trim(),
		row = matches[rowsMatchIndex].trim();

		return {
			'length' : column,
			'width' : row
		};
	}

	return null;
}

function combineSizeDimensions($sizes) {
	var sizes = $sizes.toArray(),
	lengths = {};

	var parser = new RegExp(page.parseExpression);

	sizes.forEach(function(val, index) {
		var $length = $(val);
		var size = parseSizeValue($(val).text(), parser);
		if (size) {
			if (lengths.hasOwnProperty(size.length)) {
				$length.remove();
				var $li = $('<li />');
				var $link = $('<a />');
				$li.append($link);
				$link
				.attr('href', $length.find('a').attr('href'))
				.text(size.width);
				$('li.' + lengths[size.length]).find('ul').append($li);
			} else {
				var klass = $length.attr('class'),
				linkHref = $length.find('a').attr('href');

				lengths[size.length] = klass;

				$('.' + lengths[size.length]).find('a')
				.text(size.length)
				.attr('href', '#')
				.attr('class', 'slide-down');

				$('li.' + lengths[size.length]).append('<ul />');

				var $li = $('<li />'),
				$link = $('<a />');

				$link
				.attr('href', linkHref)
				.text(size.width);

				$li.append($link);

				$('li.' + lengths[size.length]).find('ul').append($li);
			}
		}
	});
}

var sizeRefinements = {
	init: function() {
		var $sizes = $('.swatches.size li');

		if ($sizes.length > 0) {
			combineSizeDimensions($sizes);
		}
		
		initializeEvents();
	}
};


module.exports = sizeRefinements;
