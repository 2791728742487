/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */

'use strict';

var countries = require('./countries'),
    dialog = require('../../../app_storefront_core/cartridge/js/dialog'),
    minicart = require('./minicart'),
    page = require('./page'),
    rating = require('./rating'),
    //tooltip = require('./tooltip'),
    util = require('./util'),
    validator = require('./validator'),
    tls = require('./tls'),
  	TPromise = require('promise'),
  	ajax = require('./ajax'),
  	util = require('./util'),
  	rotate3d = require('./productRotate3d.js'),
    count_down = require('./countdown'),
	wishlist = require('../../../app_storefront_core/cartridge/js/wishlist'),
	loginDialog = require('../../../app_storefront_core/cartridge/js/loginDialog'),
	login = require('../../../app_storefront_core/cartridge/js/login'),
	register = require('../../../app_storefront_core/cartridge/js/register'),
	alert = require('../../../app_storefront_core/cartridge/js/alert'),
	searchbar = require('../../../app_storefront_core/cartridge/js/searchbar'),
	sliders = require('../../../app_storefront_core/cartridge/js/sliders.js');

var giftCardBalanceInquiryHelper = require('../../../app_storefront_core/cartridge/js/helpers/GiftCardBalanceInquiryHelper');
var GoogleTagManagerClient = require('../../../bc_sheplersbootbarn_core/cartridge/js/util/GoogleTagManagerClient');
var RequestHelper = require('../../../app_storefront_core/cartridge/js/helpers/RequestHelper');
var SiteConstants = require('../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');

// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js');
    s.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(s);
}

require('../../../app_storefront_core/cartridge/js/jquery-ext')();
require('./cookieprivacy')();
require('./captcha')();
require('./watermark')();


function initializeEvents() {
    var controlKeys = ['8', '13', '46', '45', '36', '35', '38', '37', '40', '39'],
    	$win = $(window),
    	$doc = $(document);

    $.fn.hasAttr = function(name) {
		return this.attr(name) !== undefined;
	};
	
	$(document).off('PostAjaxAuthenticationActions.Initialize').on('PostAjaxAuthenticationActions.Initialize', function(event, options) {
		var data = options.results;
		var isAccountDialog = options.isAccountDialog;
		if (isAccountDialog) {
			var customerFirstName = data.customerFirstName ? ', ' + data.customerFirstName + '.' : '.';
			var alertMessage;
			if (options.displayMessage == 'login') {
				alertMessage = 'Welcome back' + customerFirstName;
			}
			if (options.displayMessage == 'register') {
				alertMessage = 'Thanks for creating an account' + customerFirstName;
			}
			
			if (SitePreferences.WISHLIST_ENABLED) { // Future-forward: in case we ever implement login/register dialogs other than with wishlist
				/**** Wishlist icon updates ****/
				var wishlistItems = data.controllerResults && data.controllerResults.allWishlistItems ? data.controllerResults.allWishlistItems : data.allWishlistItems ? data.allWishlistItems : null;
				var returnedResult = data.controllerResults && data.controllerResults.result ? data.controllerResults.result : data.result ? data.result : null;
				if (wishlistItems != null) {
					window.localStorage.WishlistProductIDs = JSON.stringify(wishlistItems);
					wishlist.renderAllWishlistButtons();
					if (returnedResult == 'complete') {
						alertMessage += ' This item is already saved to your Wishlist.';
					} else {
						alertMessage += ' An item has been added to your Wishlist.';
					}
				}
			}

			/**** Minicart update ****/
			if (data.cartQuantity) {
				var $miniCartElm = $('.mini-cart .minicart-quantity').length > 0 ? $('.mini-cart .minicart-quantity') : $('.mini-cart .btn-minicart .btn-minicart-qty');
				$miniCartElm.html(data.cartQuantity);
			}

			/**** My Account navigation update ****/
			var profileName = data.customerFirstName ? ' ' + data.customerFirstName : '';
			$('.first-name.has-dropdown a').first().find('span').html('Hello' + profileName);
			$('.nav-secondary-dropdown.login-dropdown').remove();

			/**** Client Window > Customer object update ****/
			Customer.authenticated = true;
			Customer.registered = true;
			
			/**** Close the account dialog ****/
			dialog.close();

			/**** Show the alert ****/
			alert.init({
				type: 'alert-primary',
				timer: 3000,
				content: alertMessage
			});
		} else {
			window.location.href = data.registerDialogContinueUrl ? data.registerDialogContinueUrl : data.loginDialogContinueUrl ? data.loginDialogContinueUrl : location.reload();
		}
	});
	
	function CheckloginCreate() {
		var loginCreateCount = $(".login-create-account").length;
		if(loginCreateCount == 0) {
		$(".order-confirmation-details").addClass("fullWidth");
		}else {
			$(".order-confirmation-details").removeClass("fullWidth");
		}
	} 
	
	$(document).on('click', '.review', function(e){
		if (typeof $BV !== 'undefined') {
			var productID = $(this).data('product-id');
			if (typeof productID !== 'undefined') {
				productID = productID.toString();
				if (productID.length > 0) {
					e.preventDefault();
					
					if (typeof $BV !== 'undefined') {
						$BV.ui("rr", "submit_review", {productId: productID});
					}
				}
			}
		}
	});

	setInterval(function() {
		CheckloginCreate();
	}, 2000);
	
	$('.section-group .section-body:last').append($('.section-reviews'));
	
	$(".pt_customer-service #secondary .nav-title").click(function() {
		$(this).toggleClass('active');
		$(".pt_customer-service #secondary #customer-service-nav").slideToggle();
	});
	
	if (util.isTouchDevice()) {
		$('body').addClass('touchDevice');
	}
		
	$('.new-customer-header-btn').on('click',function(e) {
		e.preventDefault();
		var hrf = $(this).attr('href');
		var confirmVal = confirm(Resources.NEW_CUSTOMER_CONFIRM);
		if ( confirmVal ) {
			window.location = hrf;
		} else { return false; }
	});

	searchbar.init();
	
	$('input,textarea').focus(function(){
	   $(this).data('placeholder',$(this).attr('placeholder'))
	          .attr('placeholder','');
	}).blur(function(){
	   $(this).attr('placeholder',$(this).data('placeholder'));
	});
	
	var brands_count = $('.brand').length;
	var styles_count = $('.style').length;
	var toestyle_count = $('.toe-style').length;

	if(brands_count < 16) {
		$('.pt_content .viewallbrands').hide();
	}

	if(styles_count < 8) {
		$('.pt_content .viewallstyles').hide();
	}

	if(toestyle_count < 8) {
		$('.pt_content .viewalltoes').hide();
	}

	$(document).on('click','.ca-header-contact-info',function(event) {
		if(window.innerWidth > 767) {
			event.preventDefault();
		}
	});
	$('.footer .contacts .ca-footer-content a').on('click',function(event) {
		if(window.innerWidth > 767) {
			event.preventDefault();
		}
	});

	if(window.innerWidth < 767) {
		$('.pt_content .brand:nth-child(n+9)').css({'display':'none'});
		$('.pt_content .style:nth-child(n+9)').css({'display':'none'});
		$('.pt_content .toe-style:nth-child(n+9)').css({'display':'none'});

	} else {
		$('.pt_content .brand:nth-child(n+9)').css({'display':'block'});
		$('.pt_content .style:nth-child(n+9)').css({'display':'block'});
		$('.pt_content .toe-style:nth-child(n+9)').css({'display':'block'});
	
		$(document).on('click','.ca-footer-content a',function(event) {
			if(window.innerWidth > 767) {
				event.preventDefault();
			}
		});
	}

	$(document).on('click','.pt_content .viewallbrands',function(event) {
		event.preventDefault();
		if($('.pt_content .viewallbrands').hasClass("expanded")) {
			$('.pt_content .viewallbrands').removeClass("expanded");
			$('.pt_content .brand:nth-child(n+9)').css({'display':'none'});
			$('.pt_content .viewallbrands').html(Resources.SHOWMOREBRANDS);
		} else {
			$('.pt_content .viewallbrands').addClass("expanded");
			$('.pt_content .brand:nth-child(n+9)').css({'display':'block'});
			$('.pt_content .viewallbrands').html(Resources.CLOSE);
		}
	});

	$(document).on('click','.pt_content .viewallstyles',function(event) {
		event.preventDefault();
		if($('.pt_content .viewallstyles').hasClass("expanded")) {
			$('.pt_content .viewallstyles').removeClass("expanded");
			$('.pt_content .style:nth-child(n+9)').css({'display':'none'});
			$('.pt_content .viewallstyles').html(Resources.SHOWMORESTYLE);
		} else {
			$('.pt_content .viewallstyles').addClass("expanded");
			$('.pt_content .style:nth-child(n+9)').css({'display':'block'});
			$('.pt_content .viewallstyles').html(Resources.CLOSE);
		}
	});

	$(document).on('click','.pt_content .viewalltoes',function(event) {
		event.preventDefault();
		if($('.pt_content .viewalltoes').hasClass("expanded")) {
			$('.pt_content .viewalltoes').removeClass("expanded");
			$('.pt_content .toe-style:nth-child(n+9)').css({'display':'none'});
			$('.pt_content .viewalltoes').html(Resources.SHOWMORETOE);
		} else {
			$('.pt_content .viewalltoes').addClass("expanded");
			$('.pt_content .toe-style:nth-child(n+9)').css({'display':'block'});
			$('.pt_content .viewalltoes').html(Resources.CLOSE);
		}
	});

	// ADA Compliance - Keyboard Tab Visual Indicators
	$(document).on('keyup', function (event) {
		if (event.which === SiteConstants.KeyCodes.Tab) {
			$(':focus').addClass('focus-visible-indicator');
		}
	}).on('keydown keypress', function (event) {
		$('.focus-visible-indicator').removeClass('focus-visible-indicator');
	});
	
	// CORDIAL ACCOUNT SIGN-UP, BROWSE, CART, ORDER
	if(SitePreferences.CORDIAL_ENABLED){		
		// plp browse
		if ($(document).find('.pt_product-search-result').length > 0) {
			var maxTiles = 10;
			var products = [];
			$('.product-tile').each(function(i) {
				if (i < maxTiles) {
					var product = $(this).attr('data-itemid');
					products.push(product);
				}
			});
			
			var properties = {
			    "category": $('#categoryTitle').text().replace(/\\/g, "").trim(),
			    "url": $(location).attr("href").split("?utm")[0].split("#utm")[0].split("&utm")[0],
			    "products": products
			};
			
			if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
				crdl('event', 'category_browse', properties);		
			}
		}
		
		// pdp browse
		if ($(document).find('.pt_product-details').length > 0) {
			setTimeout(function(){   	
				var thisItem = JSON.parse($('.productCardMetadata.pdp-browse').attr('data-product-gtm-json'));
			  	var images = [];
				$('.product-primary-image [class*="-slide"]').each(function () {
					 var image = $(this).find('a').attr('href');
					 images.push(image);
				});
				
			  	var properties = {
					"productID": thisItem["id"],
					"productName": thisItem["name"].replace(/\\/g, ""),
					"category": thisItem["category"].replace(/\\/g, ""),
					"price": thisItem["price"],
					"images": images,
			  		"url": $(location).attr("href").split("?utm")[0].split("#utm")[0].split("&utm")[0],
					"attr": {
			            "manufacturer": thisItem["brand"]
			        },
				};
				
				if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
			  		crdl('event', 'product_browse', properties); 
				}
			}, 3000);				
		}
		
		// add Cordial contact from checkout
		$('#submitBilling').on('click', function(e){
	    	e.preventDefault();
	    	if ($('.bill-address-fields .field.error').length == 0) {
				var email = $('#dwfrm_billingaddress_email').val().trim();
				var firstName = $('#dwfrm_billingaddress_firstName').val();
				var lastName = $('#dwfrm_billingaddress_lastName').val();
				var phone = $('#dwfrm_billingaddress_phone').val();
				var zip = $('#dwfrm_billingaddress_postal').val();
				var country = $('#dwfrm_billingaddress_country').val();
				var state = $('#dwfrm_billingaddress_states_state').val();
				var city = $('#dwfrm_billingaddress_city').val();
						
				if ($('input#newslettersignup').is(':checked')){
					var auth_data = {
						email: email
					}
					var contact_data = {
					    'channels': {
					        'email': {
					            'address': email,
					            'subscribeStatus': 'subscribed'
					        }
					    },
						'first_name': firstName,
						'last_name': lastName,
						'phone': phone,
						'postal_code': zip,
						'signup_source': 'checkout',
						'welcome_signup': true,
						'geo_location': {
						    'postal_code': zip,
						    'country': country,
						    'state': state,
						    'city': city
						}
					};
					
					var properties = {
					    "signup_source": 'checkout',
					    "welcome_signup": true
					};
		
					RequestHelper.Validate('Cordial-EmailSignup').then(function() {
						if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
							crdl([
								['contact', auth_data, contact_data],
								['event', 'email_signup', properties]
							]);
						}
					}).catch();
				}
			}
		});
		
		// update Cart when added on pdp and for qty change or removal in cart
		function cordialUpdateCart() {
			setTimeout(function(){
				var items = [];
				$('.order-item').each(function(index, el) {
					var _this = $(this);
					items[index] = {};
					_this.find('.productCardMetadata').each(function(key, v) {
						var thisItem = JSON.parse($(this).attr('data-product-gtm-json'));
						var product = {};	
						product.productID = thisItem["id"];
						product.sku = thisItem["skuid"];
						product.category = thisItem["category"].replace(/\\/g, "");
						product.name = thisItem["name"].replace(/\\/g, "");
						product.qty = thisItem["quantity"];
						product.itemPrice = thisItem["price"];
						product.images = [_this.find('img').attr('src')];
						product.url = window.location.origin + _this.find('.name').find('a').attr('href').split("?utm")[0].split("#utm")[0].split("&utm")[0];
						product.attr = {'manufacturer': thisItem["brand"], 'color': thisItem["color"], 'size': thisItem["size"] };
						items[index]= product;
					});
				});
				
				var cart_data = items;
		
				if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
					crdl([
						['cart', 'clear'],
						['cartitem', 'add' , cart_data], 
						['event', 'cart']
					]); 
				}
			}, 3000);
		}
		
		function cordialClearCart() {
			setTimeout(function(){
				var properties = {
				    'status': 'empty'
				};
				
				if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
					crdl([
						['cart', 'clear'],
						['event', 'cart', properties]
					]); 
				}
			}, 3000);
		}
		
		if ($(document).find('.pt_cart').length > 0 || $(document).find('.pt_onepcheckout').length > 0) {
			if($('.order-item').length > 0){
				cordialUpdateCart();
		    } else {		    	
		    	cordialClearCart();
		    }
		}
		
		$('body').on('change', 'input.opc-qty', function(event) {
			cordialUpdateCart();
		});
		
		$('body').on('click', '.cart-change', function(event) {
			setTimeout(function(){
				if($('.order-item').length == 0){
					cordialClearCart();
			    } else {
			    	cordialUpdateCart();
			    }	
	    	}, 2000);		
		});
		
		// order event created on confirmation; myaccount signup	
		if ($(document).find('.order-confirmation-details').length > 0) {
			var orderID = $('.order-history-info span.value').first().text().trim();
			var email = $('.order-billing-email').text().trim();
			var shippingName = $('.order-shipping-name').text().trim();
			var shippingAddress2 = $('.order-shipping-address2').text().trim();
			var shippingAddress3 = $('.order-shipping-address3').text().trim();
			var shippingAddress = $('.order-shipping-address1').text().trim();
			if(shippingAddress2) { 
				shippingAddress += ", " + shippingAddress2;
			}
			if(shippingAddress3) { 
				shippingAddress += ", " + shippingAddress3;
			}
			var shippingCity = $('.order-shipping-geo .city').text().trim().replace(',','');
			var shippingState = $('.order-shipping-geo .state').text().trim();
			var shippingZip = $('.order-shipping-geo .zip').text().trim();
			var shippingCountry = $('.order-shipping-country').text().trim();
			var billingName = $('.order-billing-name').text().trim();
			var billingAddress2 = $('.order-billing-address2').text().trim();
			var billingAddress3 = $('.order-billing-address3').text().trim();
			var billingAddress = $('.order-billing-address1').text().trim();
			if(billingAddress2) { 
				billingAddress += ", " + billingAddress2;
			}
			if(billingAddress3) { 
				billingAddress += ", " + billingAddress3;
			}
			var billingCity = $('.order-billing-geo .city').text().trim().replace(',','');
			var billingState = $('.order-billing-geo .state').text().trim();
			var billingZip = $('.order-billing-geo .zip').text().trim();
			var billingCountry = $('.order-billing-country').text().trim();
			var billingPhone = $('.order-billing-phone').text().replace('Phone:','').trim();
			
			var name = billingName.split(' ');
			var firstName = name[0];
			var lastName = name[name.length-1];
			
			var items = [];
			$('.order-item').each(function(index, el) {
				var _this = $(this);
				items[index] = {};
				_this.find('.productCardMetadata').each(function(key, v) {
					var thisItem = JSON.parse($(this).attr('data-product-gtm-json'));
					var product = {};	
					product.productID = thisItem["id"];
					product.sku = thisItem["skuid"];
					product.category = thisItem["category"].replace(/\\/g, "");
					product.name = thisItem["name"].replace(/\\/g, "");
					product.qty = thisItem["quantity"];
					product.itemPrice = thisItem["price"];
					product.images = [_this.find('img').attr('src')];
					product.url = window.location.origin + _this.find('.name').find('a').attr('href');
					product.attr = {'manufacturer': thisItem["brand"], 'color': thisItem["color"], 'size': thisItem["size"] };
					items[index]= product;
				});
			});
			
			var order_data = {
			  "orderID": orderID,
			  "shippingAddress": {
			    "name": shippingName,
			    "address": shippingAddress,
			    "city": shippingCity,
			    "state": shippingState,
			    "postalCode": shippingZip,
			    "country": shippingCountry
			  },
			  "billingAddress": {
				"name": billingName,
				"address": billingAddress,
				"city": billingCity,
				"state": billingState,
				"postalCode": billingZip,
				"country": billingCountry
			  },
			  "items": items
			};
			
			var properties = {
			    "orderID": orderID
			};
			
			var auth_data = {
				email: email
			}
			var contact_data = {
			    'channels': {
			        'email': {
			            'address': email
			        }
			    },
			    'first_name': firstName,
				'last_name': lastName,
				'phone': billingPhone,
				'postal_code': billingZip,
				'source_create': 'order',
				'geo_location': {
				    'postal_code': billingZip,
				    'country': billingCountry,
				    'state': billingState,
				    'city': billingCity
				}
			};				
			if (typeof crdl !== 'undefined' && typeof crdl === 'function') {	
				crdl([
					['contact', auth_data, contact_data],
					['cart', 'clear'],
					['order', 'add', order_data],
					['event', 'order', properties]
				]);
			}
				
			// create Cordial contact on confirmation page
			$(document).on('click','.largebutton[name="dwfrm_profile_confirm"]', function(){
				var password = $('.password-holder .password').val();
				var passwordConfirm = $('.password-holder .password-confirm').val();
				var formValidation = true;
				var signupEvent = "";

				var regexPassword = /^(?=.*?[A-z])(?=.*?[0-9])(?=.*?[#?!\=\/\,\.\~\`\(\)\{\}\[\]\\@_$%^&*-]).{6,15}$/g;
				if ( String(password).match(regexPassword) == null ) {
					formValidation = false;
				}
				
				if (password !== passwordConfirm) {
					formValidation = false;
				}
				
				if (!password || !passwordConfirm) {
					formValidation = false;
				}
				
				if (formValidation && $('#RegistrationForm .field.error').length == 0){
					var signupEvent = "";
					
					var auth_data = {
						email: email
					}
					
					var contact_data = {
					    'channels': {
					        'email': {
					            'address': email
					        }
					    },
						'first_name': firstName,
						'last_name': lastName,
						'phone': billingPhone,
						'postal_code': billingZip,
						'source_create': 'myaccount',
						'geo_location': {
						    'postal_code': billingZip,
						    'country': billingCountry,
						    'state': billingState,
						    'city': billingCity
						}
					};
					
					var loginProperties = {
					    "email": email
					};
					
					if ($('input#newslettersignup').is(':checked')){					
						var contact_data = {
						    'channels': {
						        'email': {
						            'address': email,
						            'subscribeStatus': 'subscribed'
						        }
						    },
							'first_name': firstName,
							'last_name': lastName,
							'phone': billingPhone,
							'postal_code': billingZip,
							'signup_source': 'myaccount',
							'source_create': 'myaccount',
							'welcome_signup': true,
							'geo_location': {
							    'postal_code': billingZip,
							    'country': billingCountry,
							    'state': billingState,
							    'city': billingCity
							}
						};
						
						var properties = {
						    "signup_source": 'myaccount',
						    "page": 'confirmation',
						    "welcome_signup": true
						};
						
						signupEvent = ['event', 'email_signup', properties];
					}
										
					RequestHelper.Validate('Cordial-EmailSignup').then(function() {
						if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
							crdl([
								['contact', auth_data, contact_data],
								['event', 'account_create'],
								['event', 'login', loginProperties],
								signupEvent
							]);
						}
					}).catch();
				}
			});
		}

		// Password Reset - No Account
		if ($(document).find('.reset-password .no-account').length > 0) {
			var email = $('.no-account').text();
	
			var auth_data = {
				email: email
			}
			var contact_data = {
			    'channels': {
			        'email': {
			            'address': email
			        }
			    }
			};
			
			var properties = {
			    "email": email
			};

			if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
				crdl([
					['contact', auth_data, contact_data],
					['event', 'reset-password-no-account', properties]
				]);
			}
		}
		
		// Password Reset Confirmation
		if ($(document).find('#password-reset-confirm').length > 0) {
			var email = $('#password-reset-confirm').attr('data-email');
			var passwordEvent = "reset-password";
			
			if ($(document).find('.noAccount').length > 0) {
				var passwordEvent = "reset-password-no-account";
			}
			
			var auth_data = {
				email: email
			}
			var contact_data = {
			    'channels': {
			        'email': {
			            'address': email
			        }
			    }
			};
			
			var properties = {
			    "email": email
			};

			if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
				crdl([
					['contact', auth_data, contact_data],
					['event', passwordEvent, properties]
				]);
			}
		}
	}
	//END CORDIAL
	
	// Reloading login page after unsuccessful attempt w/ error messaging,
	// and retaining username if 'Remember me' was checked
	if (window.location.href.indexOf('retry') >= 0) {
		$('.login-dialog-error .invalid-login').removeClass('d-none');
		if (window.location.href.indexOf('=') >= 0) {
			var username = window.location.href.split('=')[1];
			$('input[name*="dwfrm_login_username"]').val(username);
			$('input[name*="dwfrm_login_rememberme"]').prop('checked', true);
		}
	}

	$(document).on('mouseenter', '.swatch-list .swatch:not(.selected) [class*="swatch-im"]', function() {
		var $parentBlock = $(this).closest('.slide, .swatch-list');
		var $mainImg = $parentBlock.find('.product-image img:first');
		var swatchData = $(this).data('thumb');
		
		if (swatchData) {
			$mainImg.attr('alt', swatchData.alt);
			$mainImg.attr('title', swatchData.title);
			$mainImg.attr('src', swatchData.src);
		}

		$parentBlock.find('.swatch').removeClass('selected');
		$(this).closest('.swatch').addClass('selected');
	});

	$('.video-play-btn')
	.on('click', function(e) {
		var $this = $(this);
		var $video = $this.closest('.video');
		var $iframe = $video.find('iframe');
		var originalSRC = $video.find('iframe').attr('src').replace('?autoplay=1','');
		var videoHtml = $(this).next('iframe').prop('outerHTML');
		videoHtml = videoHtml.replace(/src=".+?"/, 'src="' + originalSRC + '?autoplay=1"');
		dialog.open({
            html:(videoHtml),
            options: {
            	width: 560,
                dialogClass: 'videopopup',
                title: $video.find('.video-caption').text(),
                close: function() {
                	$('.ui-dialog').find('iframe').attr('src', '');
                }
            },
		});

		e.preventDefault();
	})

	// Nav Secondary Find Dropdown Parent
	$('.nav-secondary-dropdown').parent().addClass('has-dropdown');

	// Nav Find Dropdown Parent
	$('#navigation .level-2').parent().addClass('has-dropdown');

    $('body')
        .on('keydown', 'textarea[data-character-limit]', function (e) {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length;

            if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
                e.preventDefault();
            }
        })
        .on('change keyup mouseup', 'textarea[data-character-limit]', function () {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
        })
		.on('click', '.footer-item .content-asset h3', function(event) {
			event.preventDefault();

			if(window.innerWidth < 1024) {
				$(this)
					.toggleClass('active')
					.closest('.footer-item')
					.siblings()
					.find('.active')
					.removeClass('active');

				$(this)
					.next()
					.stop()
					.slideToggle()
					.closest('.footer-item')
					.siblings()
					.find('.menu-footer')
					.slideUp();
			}
		})
		.on('click', '.btn-expand', function (event) {
			event.preventDefault();

			var element = $(this).attr('href')

			$(element)
				.toggleClass('show');

			if ($('html').hasClass('menu-active')) {
				$('.menu-toggle').removeClass('active');
				$('html').removeClass('menu-active');
			}

			if($('#mini-cart').hasClass('active')) {
				$('#mini-cart .mini-cart-link').removeClass('active');
				$('#mini-cart').removeClass('active');
			}
			
			if(element == '#search'){
                $('.top-banner').toggleClass('search-enabled');
            }
		})
		.on('click', '.btn-location', function(event) {
			event.preventDefault();

			if($('#mini-cart').hasClass('active')) {
				$('#mini-cart .mini-cart-link').removeClass('active');
				$('#mini-cart').removeClass('active');
			}

			$('html').removeClass('menu-active');

			$('.menu-toggle').toggleClass('active');

			var element = $(this).attr('href');

			$(element)
				.find('.nav-secondary-dropdown')
				.stop()
				.slideToggle()
				.parent()
				.siblings()
				.find('.nav-secondary-dropdown')
				.slideUp();

			if($('.menu-toggle').hasClass('active')) {
				$('html, body').animate({
					scrollTop: $(element).offset().top
				}, 1000);
			}
		})
		.on('click', '.menu-toggle', function (event) {
			event.preventDefault();

			$(this).toggleClass('active');
			$('html').toggleClass('menu-active');

			$('#navigation .active').removeClass('active');
			$('#navigation .level-2').slideUp();
			$('.nav-secondary-dropdown').slideUp();

			if($('#mini-cart').hasClass('active')) {
				$('#mini-cart .mini-cart-link').removeClass('active');
				$('#mini-cart').removeClass('active');
			}

			$(document).trigger('SearchBar.Hide');
		})
		.on('click', '#navigation > ul > .has-dropdown > a', function(event) {
			event.preventDefault();

			$(this)
				.toggleClass('active')
				.parent()
				.siblings()
				.find('.active')
				.removeClass('active');

			if(!$(this).hasClass('active')) {
				setTimeout(function () {
					$('#navigation .level-2').scrollTop(0);
				}, 400);
			}

			$(this)
				.next()
				.stop()
				.slideToggle(400)
				.parent()
				.siblings()
				.find('.level-2')
				.slideUp(400);

		})
		.on('click', '.nav-secondary .has-dropdown > a', function(event) {
			event.preventDefault();

			$(this)
				.next()
				.stop()
				.slideToggle()
				.parent()
				.siblings()
				.find('.nav-secondary-dropdown')
				.slideUp();
		})
		.on('mouseover', '#mini-cart', function () {
			if(window.innerWidth > 1024) {
				$(this)
					.find('.mini-cart-link')
					.addClass('active');

				$(this)
					.addClass('active');
			}

			if ($('html').hasClass('menu-active')) {
				$('.menu-toggle').removeClass('active');
				$('html').removeClass('menu-active');
			}

			$(document).trigger('SearchBar.Hide');
		})
		.on('mouseleave', '#mini-cart', function () {
			var $this = $(this);

			if(window.innerWidth > 1024) {
				setTimeout(function () {
					$this
						.find('.mini-cart-link')
						.removeClass('active');

					$this
						.removeClass('active');
				}, 1000);
			}
		})
		.on('click', '.edit-search-query', function () {
			$('.category-popup-list-stores').dialog('close');
			$('.grid-page-storeselector-popup').dialog('open');
		})
		.on('click', '.popup-list-stores input[name="apply"]', function () {
			if (!$('input[name="store"]:checked').val()) {
				alert('Select one of stores');

				return;
			}

			$.ajax({
				type: 'get',
				url: Urls.setDefaultStore,
				data: {
					storeId: $('input[name="store"]:checked').val()
				},

				success: function (data) {
					$('.category-popup-list-stores').dialog('close');
					document.location.reload();
				}
			});
		})
		.on('click', '.pt_product-search-result .category-slot a', function(event) {
			var refinementValue = $(event.target).closest('[data-link-attribute-value]').data('link-attribute-value');
			var isRefinementLink = refinementValue != undefined;

			if (isRefinementLink) {	
				var $sidebarRefinement = $('#refinements-inner').find('#field-' + refinementValue);
				var urlRefinementData = getUrlRefinementData();

				if ($sidebarRefinement.length > 0) {
					event.preventDefault();
					$sidebarRefinement.trigger('click');
				} else if (urlRefinementData.hasRefinements) {
					event.preventDefault();
					var refinementId = $(event.target).closest('[data-link-attribute-id]').data('link-attribute-id');
					var refinementIsAlreadySelected = false;
					var redirectUrl;

					for (var refinement in urlRefinementData.parameters) {
						if (urlRefinementData.parameters[refinement] === refinementValue) {
							refinementIsAlreadySelected = true;
						}
					}

					if (!refinementIsAlreadySelected) {
						redirectUrl = window.location.href + '&prefn' + urlRefinementData.newRefinementStartNumber + '=' + refinementId + '&prefv' + urlRefinementData.newRefinementStartNumber + '=' + refinementValue;
						window.location.href = redirectUrl;
					}
				}
			}
		});

		/**
		 * Add class to content slot refinements if they're currently active
		 * and remove any static refinements if they're not found in the sidebar
		 * to prevent empty search results.
		 */
		if ($('body').find('.pt_product-search-result .category-slot a').length > 0) {
			var $slotLink = $('body').find('.pt_product-search-result .category-slot a');
			var urlRefinementData = getUrlRefinementData();
			var sidebarRefinements = getSidebarRefinements();
			$slotLink.each(function(index, value) {
				var linkRefinementType = $(value).data('link-attribute-value');
				for (var refinement in urlRefinementData.parameters) {
					if (refinement.match(/^\prefv/)) {
						var refinementValues = urlRefinementData.parameters[refinement].split('%7C');
						for (var index = 0; index < refinementValues.length; index++) {
							if (refinementValues[index] === linkRefinementType) {
								$(value).addClass('refinement-active');
							}
						}
					}
				}
				if (linkRefinementType != undefined) {
					var foundInSidebar = false;
					for (var index = 0; index < sidebarRefinements.length; index++) {
						if (sidebarRefinements[index] === linkRefinementType) {
							var foundInSidebar = true;
						}
					}
					if (!foundInSidebar) {
						$(value).closest('li').remove();
					}
				}
			});
			$slotLink.closest('.section-block').removeClass('hidden');
		}

		/**
		 * helper function to get a list of existing refinements from the URL
		 */
		function getUrlRefinementData() {
			var refinements = location.search.substr(1).split('&');
			var urlRefinementData = {
				newRefinementStartNumber: 1,
				parameters: {},
				hasRefinements: false
			};
			if (refinements.length > 0) {
				for (var index = 0; index < refinements.length; index++) {
					var parameter = refinements[index].split('=');
					if (parameter[0].match(/^\prefn/)) {
						urlRefinementData.newRefinementStartNumber++;
						urlRefinementData.hasRefinements = true;
						urlRefinementData.parameters[parameter[0]] = parameter[1];
					}
					if (parameter[0].match(/^\prefv/)) {
						urlRefinementData.parameters[parameter[0]] = parameter[1];
					}
				}
			}
			return urlRefinementData;
		}

		/**
		 * helper function to get a list of refinement names present in refinement sidebar
		 */
		function getSidebarRefinements() {
			var sidebarRefinementList = [];
			$('#secondary.refinements .checkbox input').each(function(index, value) {
				if ($(value).attr('id').match(/^field/)) {
					var refinementName = $(value).attr('id').split('-')[1];
					sidebarRefinementList.push(refinementName);
				}
			});
			return sidebarRefinementList;
		}

		// ADA Compliance - Keyboard menu navigation & site visual indicators
		$(document).on('keyup', function (event) {
			if (event.which === SiteConstants.KeyCodes.Tab) {
				$(':focus').addClass('focus-visible-indicator');
			}
		}).on('keydown keypress', function (event) {
			$('.focus-visible-indicator').removeClass('focus-visible-indicator');
		});

		/* --- ADA Compliance: Keyboard main menu navigation ---
		* ARROW DOWN: Will open flyouts; focus goes to first clickable link in flyout
		* ARROW UP: Will close flyouts; focus returns to the parent of the flyout
		* TAB: A focus indicator will show up at time of each tab
		* ENTER: By default, hitting enter will engage any link that has current focus
		*/
		$(document).on('keyup', 'ul.menu-category li.has-dropdown', function (event) {
			// Down Arrow: If sub-menu has been opened... 
			if (event.which === SiteConstants.KeyCodes.ArrowDown) {
				$(this).children('a').toggleClass('active').parent().siblings().find('.active').removeClass('active');
				if(!$(this).children('a').hasClass('active')) {
					setTimeout(function () {
						$('#navigation .level-2').scrollTop(0);
					}, 400);
				}
				$(this).children('a').next().stop().slideDown(400).parent().siblings().find('.level-2').slideUp(400);
				$(this).find('ul').find('a[href!=""][href]').removeAttr('tabindex'); // Remove tabindex to allow natural tab flow of list after menu is open
				$(this).find('.level-2 ul:first-of-type li:first-of-type a').first().focus().addClass('focus-visible-indicator'); // Focus on first <a> within opened sub-menu and add visual indicator
				$(this).addClass('keyboard-activated').attr('aria-expanded','true'); // Update expanded attribute and add keyboard class	
			}
			// Up Arrow: If sub-menu has been closed...
			if (event.which === SiteConstants.KeyCodes.ArrowUp) {
				$(this).children('a').removeClass('active').next().stop().slideUp(400).parent().siblings().find('.level-2').slideUp(400);
				$(this).find('ul').find('a[href!=""][href]').attr('tabindex','-1'); // Add negative tabindex to skip nested anchors when submenu is closed
				$(this).attr('aria-expanded','false').removeClass('active keyboard-activated').focus().addClass('focus-visible-indicator'); // Update expanded attribute, remove keyboard class and reposition focus indicator
			}
			// Tab: Keyboard logic for nav tabbing...
			if (event.which === SiteConstants.KeyCodes.Tab) {
				if (!$(document.activeElement).hasClass('keyboard-activated') && ($(document.activeElement).closest('li.has-dropdown') && !$(document.activeElement).closest('li.has-dropdown').hasClass('keyboard-activated'))) { // If user tabbed away from activated sub-menu, close the sub-menu
					$('li.has-dropdown.keyboard-activated').children('a').removeClass('active').next().stop().slideUp(400).parent().siblings().find('.level-2').slideUp(400);
					$('li.has-dropdown.keyboard-activated').find('ul').find('a[href!=""][href]').attr('tabindex','-1'); // Add negative tabindex to skip nested anchors when submenu is closed
					$('li.has-dropdown.keyboard-activated').attr('aria-expanded','false').removeClass('active keyboard-activated'); // Update expanded attribute and remove keyboard class
				}
			}
		})
		.on('keydown', '.menu-toggle', function (event) { 
			if (($('html').hasClass('size-mobile') || $('html').hasClass('size-tablet')) && event.which === SiteConstants.KeyCodes.Enter) {
				$('.my-store-panel.mobile-only a').focus().addClass('focus-visible-indicator');
			}
		})
		.on('mouseover', 'ul.menu-category li.has-dropdown', function (event) {
			$(this).attr('aria-expanded','true'); // CSS-expaned sub-menu occurs on hover. Use JS to update the expanded attribute for mouse users.
		})
		.on('mousemove', function (event) {
			$('.focus-visible-indicator').removeClass('focus-visible-indicator');
			if ($('li.has-dropdown.keyboard-activated')) {
				$('li.has-dropdown.keyboard-activated').children('a').removeClass('active').next().stop().slideUp(400).parent().siblings().find('.level-2').slideUp(400);
				$('li.has-dropdown.keyboard-activated').find('ul').find('a[href!=""][href]').attr('tabindex','-1'); // Add negative tabindex to skip nested anchors when submenu is closed
				$('li.has-dropdown.keyboard-activated').attr('aria-expanded','false').removeClass('active keyboard-activated'); // Update expanded attribute and remove keyboard class
			}
		});

		/* --- Document Ready --- */
		$(document).ready(function() {
			var adaSubMenuAttributes = {
				'role': 'menuitem',
				'aria-haspopup': 'true',
				'aria-expanded': 'false'
			}
			$('ul.menu-category.level-1 ul').attr('role','menu').attr('aria-label', function(n, x) {
				return $(this).closest('li').find('.has-sub-menu').first().html();
			});
			$('ul.menu-category.level-1').find('a').filter(function(n, navLink) {
				// Add role="none" to all list items, and then use specificity further down to override if needed
				$(navLink).parents('ul:not(.level-1)').find('li').attr('role','none');
				// Add tabindex="-1" to all nested anchor tags to force tab order using arrow up and down events
				$(navLink).attr('tabindex','-1');
				// IF the href attribute is not empty, apply ADA attributes correctly for a menuitem
				if ($(navLink).attr('href') && $(navLink).attr('href').trim().length > 0) {
					$(navLink).attr('role','menuitem');
				// ELSE, if href attribute is empty, then ensure <a> gets skipped in keyboard navigation and screen readers
				} else {
					$(navLink).parent().attr(adaSubMenuAttributes);
				}
			});
		});

		// Switch show text
		$('.switch input').change(function () {
			if($(this).prop('checked')) {
				$(this)
					.closest('.result-options')
					.addClass('show-info');
			} else {
				$(this)
					.closest('.result-options')
					.removeClass('show-info');
			}
		});

		if($('body').find('.result-options .switch input[name="field-true"]').prop('checked')) {
			var toggleBtn = $('body').find('.result-options .switch input[name="field-switch"]');

			if(!toggleBtn.prop('checked')) {
				toggleBtn.closest('.result-options').addClass('show-info');
				toggleBtn.closest('.result-options').find('input[name="field-switch"]').attr('checked', 'checked');
			} else {
				toggleBtn.closest('.result-options').removeClass('show-info');
				toggleBtn.closest('.result-options').find('input[name="field-switch"]').removeAttr('checked');
			}
		}

		$('.swatches > li > ul').on('click', 'a', function(event) {
			event.preventDefault();

			$(this)
				.toggleClass('current')
				.parent()
				.siblings()
				.find('.current')
				.removeClass('current');
		});

		$('.detail-toggle-item').on('click', function (event) {
			event.preventDefault();

			$('.discount-details .discount-details-entry').toggleClass('open');
		});

		$('.service-toggle-item').on('click', function (event) {
			event.preventDefault();

			$(this).toggleClass('active');

			$('.customer-service .customer-service-inner').toggleClass('open');
			$('.customer-service .customer-service-inner').stop().slideToggle();
		});

		// Generic slider init
		sliders.init('.slider .slides');

		$(document).on('update', '.slider-products-primary', function() {
    		sliders.init('.slider-products-primary .slides', {breakpoints: {0: {slidesToShow: 1, dots: true}, 768: {slidesToShow: 3}, 1024: {slidesToShow: 4}}});
		});

	    // make sure slider gets initialized for product recommendations when dynamically added
		$(document).ready(function() {
			if ($('.pt_product-details').length > 0 || $('.no-hits-banner').length > 0) {
				sliders.initializeRecommendationSlider();
			}
		});

		$(document).on('init-slider', '.slider-products-primary', function() {
			sliders.init('.slider-products-primary .slides:not(.slick-slider):has(li)', {breakpoints: {0: {slidesToShow: 1, dots: true, arrows: true}, 768: {slidesToShow: 3, dots: false, arrows: true}, 1024: {slidesToShow: 4, arrows: true}}}, true);
		});
		
		sliders.init('.slider-filmstrip .slides', {loop: true});

		sliders.initializeCategorySliders('.cat-header-content.cat-header-slider:not(.slick-initialized)');

		// Waypoints
		$('.waypoint').on('click', function(event) {
			event.preventDefault();

			var element = $(this).attr('href');

			$('html, body').animate({
				scrollTop: $(element).offset().top
			}, 1000);
		});

		// Btn Expand Alt
		$('.btn-expand-alt').on('click', function(event) {
			event.preventDefault();
			event.stopPropagation();

			var element = $(this).attr('href');


			if ($(element).hasClass('grid-page-storeselector-popup')) {
				$(element).dialog('open');

				return;
			}

			$(element)
				.toggleClass('show');
		});

		$('.grid-page-storeselector-popup').dialog({
			width: 450,
			autoOpen: false,
			modal: true,
			resizable: false
		});

		// Sidebar Close
		$('#secondary .close, #sort-by .close, .popup-close').on('click', function (event) {
			event.preventDefault();

			$(this)
				.closest('.show')
				.removeClass('show');
		});

		$('.message .close').on('click', function (event) {
			event.preventDefault();

			$(this)
				.parent()
				.slideUp();
		});

		// Slider only on mobile devices
		var $sliderMobile = $('.slider-mobile');

		// Resize Helper
		var breakpointIsTablet 	= false;
		var $navSecondary 			= $('.nav-secondary');
		var $navUtilities 			= $('.nav-utilities');
		var $navUl 					= $('#navigation > ul');
		var $topBannerBar 			= $('.top-banner-bar');
		var $topBannerBarInner		= $('.top-banner-bar-inner');
		var $navigation 			= $('#navigation');
		var $topBannerInnerLinks 	= $('.top-banner-inner-links');
		var $selectSort 			= $('#sort-by');
		var $secondaryHead 			= $('#secondary .refinements-head');
		var $searchResultContainer 	= $('#search-result');
		var $productDetailHead  	= $('#pdpMain .product-detail-head:not(".alt")');
		var $productContainerImage	= $('#pdpMain .product-image-container');
		var $productDetail			= $('#pdpMain .product-detail');
		var $sectionCheckout 		= $('.section-checkout');
		var $mainInnerAlt			= $('.main-inner-alt');
		var $sectionErrorFigure 	= $('.section-error figure');
		var $sectionErrorBox 		= $('.section-error .box');
		var $footerMenuItems		= $('.footer-item .content-asset');

		function resizeHelper () {
			var sliderMobileSwiper;

			if (window.innerWidth <= 767) {
				if(breakpointIsTablet) {
					return;
				}
				breakpointIsTablet = true;

				$navSecondary.insertAfter($navUl);
				$navigation.insertAfter($topBannerInnerLinks).removeClass('hidden');
				$productDetailHead.prependTo($productContainerImage);
				$sectionErrorFigure.insertBefore($sectionErrorBox);

				if ($sliderMobile.length > 0) {
					sliderMobileSwiper = sliders.init('.slider-mobile', {dots: true, loop: true});
				}

				var image = $(".slider-single-image img");
				$.removeData(image, 'elevateZoom');
				$('.zoomContainer').remove();
				$('.accordion-mobile').each(function () {
					var accMobile = $(this);

					if (!accMobile.hasClass('active')) {
						accMobile.find('.accordion-mobile-body').hide();
					}
				});

			} else {
				/*Remove duplicate block. extra rendering in image.js if width less than 1024*/
				$('div[class*="product-image-container"]').find('header.product-detail-head').remove();

				if(!breakpointIsTablet) {
					return;
				}

				breakpointIsTablet = false;

				$navSecondary.prependTo($topBannerBar);
				$navigation.insertBefore($topBannerInnerLinks).removeClass('hidden');
				$productDetailHead.prependTo($productDetail);
				$sectionErrorFigure.insertAfter($sectionErrorBox);

				$('html').removeClass('menu-active');
				$('.menu.toggle').removeClass('active');

				if ($sliderMobile.length > 0) {
					if ($sliderMobile.find('.swiper-initialized').length > 0) {
						sliderMobileSwiper.destroy();
					}
					if ($sliderMobile.find('.slick-initialized').length > 0) {
						$sliderMobile.slick('unslick');
					}
				}
				$('.accordion-mobile-body').show();
			}
		}

		$win.on('load resize', function () {
			resizeHelper();
		});

		// Back to Top button
		// Disabled per BOOT-3517
//		$win.on('scroll', function() {
//			var $scrollTop = $win.scrollTop();
//			if ($scrollTop >  100 ) {
//				$( '.btn-go-top' ).addClass('active');
//			} else {
//				$( '.btn-go-top' ).removeClass('active');
//			}
//		});

		$win.on('load', function() {
			accordionMobile();
			accordionMobileAlt();

//			$( '<span class="btn-go-top"><i class="fa fa-angle-up" aria-hidden="true"></i><span>Top</span></span>' ).insertBefore($('.footer:not(.footer-mobile)'));
		});

		$doc.on('click', '.btn-go-top', function(e){
			$('html, body').animate({scrollTop: 0},800);
			e.preventDefault();
		})

		// Read More / Less
		$doc.on('click', '.section-offers-text .read-more', function(e) {
			e.preventDefault();
			$(this).parents('.section-body').first().addClass('expand');
			return false;
		});

		$doc.on('click', '.section-offers-text .read-less', function(e) {
			e.preventDefault();
			$(this).parents('.section-body').first().removeClass('expand');
			return false;
		});
		
		
		// Accordion
		function accordionMobile() {

			$('.offer .offer-head h6 .chevron-collapse').on('click', function (event) {
				if (window.innerWidth > 1024) {
					return;
				}

				event.preventDefault();
				var $this = $(this)
				var $parent = $this.closest('.offer')

				console.log($parent)
				if ($parent.hasClass("active")) {
					$parent.removeClass('active').find('.offer-body').slideUp();

				}
				else {
					$parent.addClass('active').find('.offer-body').slideDown();
				}

			});

			$win.resize(function() {
				if(window.innerWidth > 1024) {
					$('.offer .offer-body').show();
				} else {
					$('.offer .offer-body').hide()
				}
			});
		};

		function accordionMobileAlt() {
			$('.accordion-mobile .accordion-mobile-head').on('click', function (event) {
				if (window.innerWidth > 1024) {
					return;
				}

				event.preventDefault();
				var $this = $(this),
					$parent = $this.closest('.accordion-mobile');

				$this
				.toggleClass('active')
				.next()
				.stop()
				.slideToggle();
			});
		};

		// Accordion
		$(document).on('click', '.accordion-section .accordion-head', function(event) {
			event.preventDefault();
			$(this).toggleClass('active');
			$(this).next('.accordion-body').stop().slideToggle(400);
		});

		// Rotate Text
		function rotateText ($element, duration) {
			var lengthSlider 			= $element.find('.rotate-element').length;
			var $rotateElement 			= $element.find('.rotate-element');

			setInterval(function () {
				var $rotateElementActive 	= $element.find('.active');
				var $nextElement = $rotateElementActive.next();

				if($nextElement.index() === -1) {
					$nextElement = $element.find('.rotate-element:first-child');

					$nextElement
						.addClass('active')
						.siblings()
						.removeClass('active');
				} else {
					$nextElement
						.addClass('active')
						.siblings()
						.removeClass('active');
				}
			}, duration);
		}
		rotateText($('.top-banner-message'), $('.top-banner-message').data('duration'));

    // add show/hide navigation elements
    $('.secondary-navigation .toggle').click(function () {
        $(this).toggleClass('expanded').next('ul').toggle();
    });

    // add generic toggle functionality
    $('.toggle:not(.disabled)').not('.expanded').next('.toggle-content').hide();
    $('.toggle:not(.disabled)').click(function () {
        $(this).toggleClass('expanded').next('.toggle-content').toggle();
    });

    // subscribe email box
    var $subscribeEmail = $('.subscribe-email');
    if ($subscribeEmail.length > 0)    {
        $subscribeEmail.focus(function () {
            var val = $(this.val());
            if (val.length > 0 && val !== Resources.SUBSCRIBE_EMAIL_DEFAULT) {
                return; // do not animate when contains non-default value
            }

            $(this).animate({color: '#999999'}, 500, 'linear', function () {
                $(this).val('').css('color', '#333333');
            });
        }).blur(function () {
            var val = $.trim($(this.val()));
            if (val.length > 0) {
                return; // do not animate when contains value
            }
            $(this).val(Resources.SUBSCRIBE_EMAIL_DEFAULT)
                .css('color', '#999999')
                .animate({color: '#333333'}, 500, 'linear');
        });
    }

    $('.privacy-policy').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                height: 600
            }
        });
    });
    
    $( ".user-info" ).hover(
    	  function() {
    		 $(this).addClass('active');
    	  },
    	  function() {
    		 $(this).removeClass('active');
    	  }

    );
    
    // add show/hide password functionality
	$(document).on('click', '[data-action="toggle-password-visibility"]', function(e) {
		e.preventDefault();
		var $button = $(e.target);
		var $passwordInput = $button.closest('.password-holder').find('input');
		var passwordInputType = $passwordInput.attr('type');
		if (passwordInputType === 'password') {
			$button.addClass('password-visibility--visible');
			$button.removeClass('password-visibility--invisible');
			$passwordInput.attr('type', 'text').focus();
		} else {
			$button.addClass('password-visibility--invisible');
			$button.removeClass('password-visibility--visible');
			$passwordInput.attr('type', 'password').focus();
		}
	});

	// BOOT-5363: show/hide total savings message based on promotion callout messages
	$('.product-tile').each(function() {
		var $tile = $(this);
		if ($tile.find('.hide-total-savings').length > 0) {
			$tile.find('.product-total-savings').addClass('d-none');
		}
	});
	if($('.product-detail').find('.hide-total-savings').length > 0) {
		$('.product-total-savings').addClass('d-none');
	}
}
/**
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $('html').addClass('js');
    // load js specific styles
    util.limitCharacters();

    // DOM EVENT FOR HEADER STORE LOCATOR
	$('.stores-near-content .list-stores').delegate( 'a.make-this-mystore', 'click',function(e){
		e.preventDefault();
		var postCode = $(this).attr('data-postalcode');
		$('a.ico-crosshairs-use-my-location').data('postalcode',postCode);
		headerNavStoreSearch();
	});

    $('a.ico-crosshairs-use-my-location').on('click',function(e){
    	e.preventDefault();
    	var orgPostalCode = $('a.ico-crosshairs-use-my-location').data('origpostalcode');
		$('a.ico-crosshairs-use-my-location').data('postalcode',orgPostalCode);
    	headerNavStoreSearch();
	})

}

function usemylocation(storeid) {
	var t_url = Urls.usecurrentlocation;
	if (storeid === undefined) {
		storeid = 'getall';
	}

	$.ajax({
	   url: t_url,
	   type: 'POST',
	   data: { 'source' : 'ajax' },
	   dataType: 'json',
	   error: function() {
		   console.log('error');
	   },
	   success: function(dataJson) {
	      
	      if( 'stores' in dataJson && dataJson['stores'].length > 0 ) {
	    	  	if(storeid == 'getall') {
	    	  		$('.stores-near-content .stores-near-body ul.list-stores').html('');
	    	  	}
				$('div.stores-near-actions a.go-to-findstore').html(Resources.FIND_MORE_STORES);
				if ( dataJson['stores'].length == 1 ) {
					$('.stores-near-content').slideUp();
				}
				dataJson['stores'].forEach(function(element, index){
					var store = element;
					var htmlDisplay = '';
					var storePhoneNumber = ('' + element['phone']).replace(/\D/g,'');
					var postalCodeVal = ( typeof element['postalCode'] != 'undefined' ? element['postalCode']  : '' );

					if(storeid == 'getall') {
						if(index == 0) {
							htmlDisplay += '<span class="red store-name-value">'+element['name']+'</span>';
							htmlDisplay += element['address1'];
							htmlDisplay += '<br/>'+ ( typeof element['city'] != 'undefined' ? element['city'] +', ' : '' );
							htmlDisplay += ( typeof element['stateCode'] != 'undefined' ? element['stateCode'] +' ' : '' );
							htmlDisplay += postalCodeVal;
							htmlDisplay += '<br /><a href="tel:'+storePhoneNumber+'">'+store['phone']+'</a>';
							htmlDisplay += '<span>'+unescape(store['storeHours'])+'</span>';
							$('.stores-dropdown .current-store address').html(htmlDisplay);
							$('.stores-dropdown .current-store').slideDown();
						} else {
							htmlDisplay += '<li><h5><span>'+element['name']+'</span><a href="#" class="make-this-mystore" id="' + element['ID'] + '" data-postalcode="'+postalCodeVal+'">'+Resources.MAKE_THIS_MYSTORE+'</a></h5>';
							htmlDisplay += '<address>'+element['address1'];
							htmlDisplay += '<br/>'+ ( typeof element['city'] != 'undefined' ? element['city'] +', ' : '' );
							htmlDisplay += ( typeof element['stateCode'] != 'undefined' ? element['stateCode'] +' ' : '' );
							htmlDisplay += postalCodeVal;
							htmlDisplay += '<br /><a href="tel:'+storePhoneNumber+'">'+store['phone']+'</a>';
							htmlDisplay += '/<address>' + unescape(store['storeHours']);
							htmlDisplay += '</li>';
							$('.stores-near-content .stores-near-body ul.list-stores').append($(htmlDisplay));
							$('.stores-near-content').slideDown();
						}
					} else {
						if(storeid == element['ID']) {
							htmlDisplay += '<span class="red store-name-value">'+element['name']+'</span>';
							htmlDisplay += element['address1'];
							htmlDisplay += '<br/>'+ ( typeof element['city'] != 'undefined' ? element['city'] +', ' : '' );
							htmlDisplay += ( typeof element['stateCode'] != 'undefined' ? element['stateCode'] +' ' : '' );
							htmlDisplay += postalCodeVal;
							htmlDisplay += '<br /><a href="tel:'+storePhoneNumber+'">'+store['phone']+'</a>';
							htmlDisplay += '<span>'+unescape(store['storeHours'])+'</span>';
							$('.stores-dropdown .current-store address').html(htmlDisplay);
							$('.stores-dropdown .current-store').slideDown();
						}
					}

				});
			} else {
				$('.stores-near-content').slideUp();
				$('div.stores-near-actions a.go-to-findstore').html(Resources.FIND_STORES);
				$('.stores-dropdown .current-store').slideUp();
				if ( $('.stores-dropdown h3.no_results').length == 0 ) {
					$('.stores-dropdown').prepend('<h3 class="no_results">'+Resources.NO_STORE_RESULTS+'</h3>');
				} else {
					$('.stores-dropdown h3.no_results').fadeIn();
				}
				$('.stores-dropdown h3.no_results').show().delay(2000).fadeOut();
		  }
	   }
	});
}

/*
function headerNavStoreSearch() {
	var t_url = $('a.ico-crosshairs-use-my-location').attr('href');
	var mloc = $('a.ico-crosshairs-use-my-location');
	$.ajax({
		url : t_url,
		type : 'POST',
		dataType: 'json',
		data : {
			dwfrm_storelocator_countryCode : mloc.data('countrycode'),
			dwfrm_storelocator_distanceUnit : mloc.data('distanceunit'),
			dwfrm_storelocator_postalCode : mloc.data('postalcode'),
			dwfrm_storelocator_maxdistance : Resources.FINDSTORE_DISTANCE,
			dwfrm_storelocator_findbyzip : Resources.FIND,
			format : "ajax",
		},
		success : function(dataJson){
			if( 'stores' in dataJson && dataJson['stores'].length > 0 ) {
				$('.stores-near-content .stores-near-body ul.list-stores').html('');
				$('div.stores-near-actions a.go-to-findstore').html(Resources.FIND_MORE_STORES);
				if ( dataJson['stores'].length == 1 ) {
					$('.stores-near-content').slideUp();
				}
				dataJson['stores'].forEach(function(element, index){
					var store = element;
					var htmlDisplay = '';
					var storePhoneNumber = ('' + element['phone']).replace(/\D/g,'');
					var postalCodeVal = ( typeof element['postalCode'] != 'undefined' ? element['postalCode']  : '' );
					if(index == 0) {
						htmlDisplay += '<span class="red store-name-value">'+element['name']+'</span>';
						htmlDisplay += element['address1'];
						htmlDisplay += '<br/>'+ ( typeof element['city'] != 'undefined' ? element['city'] +', ' : '' );
						htmlDisplay += ( typeof element['stateCode'] != 'undefined' ? element['stateCode'] +' ' : '' );
						htmlDisplay += postalCodeVal;
						htmlDisplay += '<br /><a href="tel:'+storePhoneNumber+'">'+store['phone']+'</a>';
						htmlDisplay += '<span>'+unescape(store['storeHours'])+'</span>';
						$('.stores-dropdown .current-store address').html(htmlDisplay);
						$('.stores-dropdown .current-store').slideDown();
					} else {
						htmlDisplay += '<li><h5><span>'+element['name']+'</span><a href="#" class="make-this-mystore" data-postalcode="'+postalCodeVal+'">'+Resources.MAKE_THIS_MYSTORE+'</a></h5>';
						htmlDisplay += '<address>'+element['address1'];
						htmlDisplay += '<br/>'+ ( typeof element['city'] != 'undefined' ? element['city'] +', ' : '' );
						htmlDisplay += ( typeof element['stateCode'] != 'undefined' ? element['stateCode'] +' ' : '' );
						htmlDisplay += postalCodeVal;
						htmlDisplay += '<br /><a href="tel:'+storePhoneNumber+'">'+store['phone']+'</a>';
						htmlDisplay += '/<address>' + unescape(store['storeHours']);
						htmlDisplay += '</li>';
						$('.stores-near-content .stores-near-body ul.list-stores').append($(htmlDisplay));
						$('.stores-near-content').slideDown();
					}
				});
			} else {
				$('.stores-near-content').slideUp();
				$('div.stores-near-actions a.go-to-findstore').html(Resources.FIND_STORES);
				$('.stores-dropdown .current-store').slideUp();
				if ( $('.stores-dropdown h3.no_results').length == 0 ) {
					$('.stores-dropdown').prepend('<h3 class="no_results">'+Resources.NO_STORE_RESULTS+'</h3>');
				} else {
					$('.stores-dropdown h3.no_results').fadeIn();
				}
				$('.stores-dropdown h3.no_results').show().delay(2000).fadeOut();
			}
		}
	})
}
*/

var pages = {
	storefront: require('../../../app_storefront_core/cartridge/js/pages/storefront'),
    account: require('./pages/account'),
    cart: require('./pages/cart'),
    checkout: require('./pages/checkout'),
	cart_echo: require('../../../app_storefront_core/cartridge/js/pages/cart_echo'),
	checkout_echo: require('../../../app_storefront_core/cartridge/js/pages/checkout_echo'),
    compare: require('./pages/compare'),
    product: require('./pages/product'),
    registry: require('./pages/registry'),
	search: require('../../../app_storefront_core/cartridge/js/pages/search'),
    storelocator: require('./pages/storelocator'),
    onepcheckout: require('../../../app_storefront_core/cartridge/js/pages/onepcheckout'),
    onepcheckout_groups: require('../../../app_storefront_core/cartridge/js/pages/onepcheckout_groups'),
    categorylanding: require('./pages/categorylanding'),
    content: require('./pages/content')
};

var app = {
    init: function () {
    	window.sgDialog = require('./dialog');
    	window.sgUtil = require('./util');

        if (document.cookie.length === 0) {
        	$('<div/>').addClass('browser-compatibility-alert cookies').append($('<p/>').addClass('browser-error').html(Resources.COOKIES_DISABLED)).appendTo('#browser-check');
            $('.browser-compatibility-alert.cookies').prepend('<i class="fa fa-exclamation-triangle fa-3x pull-left"></i>');
        }
        initializeDom();
        initializeEvents();

		login.init();
		register.init();
        // init specific global components
        countries.init();
        //tooltip.init();
        minicart.init();
        validator.init();
        rating.init();
		rotate3d.init();
        count_down.init();
        giftCardBalanceInquiryHelper.initializeAllFoundComponents();
		wishlist.init();

        // execute page specific initializations
        $.extend(page, window.pageContext);
        
        var ns = page.ns;
        if (ns && pages[ns] && pages[ns].init) {
            pages[ns].init(page.options || {});
        }

        GoogleTagManagerClient.PushDataLayerForPage(page);
        
        // Check TLS status if indicated by site preference
        if (SitePreferences.CHECK_TLS === true) {
            tls.getUserAgent();
        }

		// selected states for account navigation
		if ($(document).find('.pt_account, .pt_order').length > 0) {
			console.log("")
			var currentPage = window.location.origin + window.location.pathname;
			var isOnTopLevelPage = false;
			$('#secondary.nav a')
				.removeClass('is-active')
				.each(function(index, value) {
					if (value.href === currentPage) {
						$(this).addClass('is-active');
						isOnTopLevelPage = true;
					}
			});
			if (!isOnTopLevelPage) {
				$('#secondary.nav a').each(function(index, value) {
					if (value.href === document.referrer) {
						$(this).addClass('is-active');
					}
				});
			}
		}

		//guest checkout
		$('body').on('click', '#guestCheckout', function(event) {
			setTimeout(function(){
				if ($(document).find('.custom-guestcheckout-error').length > 0 && window.innerWidth <= 767) {
					if ($('span[id^="dwfrm_guestcheckout_username_"]').length == 0) {
						$('input[name^="dwfrm_guestcheckout_username_"]').removeClass('valid').addClass('error');
						$('.custom-guestcheckout-error').attr('style', 'display: block !important').text('Please enter a valid email address.');
					}
				}
			}, 550);
		});
		
		$('#guest_checkout_optin:checkbox').change(function() {
		   if ($('#guest_checkout_optin').prop("checked")) {
		        $('.optin-input').addClass('checked');
		    } else {
		        $('.optin-input').removeClass('checked');
		    }
		 });
    }
};

// general extension functions
(function () {
    String.format = function () {
        var s = arguments[0];
        var i, len = arguments.length - 1;
        for (i = 0; i < len; i++) {
            var reg = new RegExp('\\{' + i + '\\}', 'gm');
            s = s.replace(reg, arguments[i + 1]);
        }
        return s;
    };
})();

// initialize app
$(document).ready(function () {
	$.ajaxSetup({
		statusCode: {
			401: function () {
				location.href = Urls.RSP.Login;
			}
		}
	});

    app.init();
});
