'use strict';
var dialog = require('../dialog'),
storeMarkers = [],
infoWindows = [],
//addToCart = require('./product/addToCart'),
map,
storeMap;

function addFoundStoreMarkers() {
	// Variable setup
	var stores = $('.stores .store'),
			bounds = new google.maps.LatLngBounds();

	if (stores.length === 0) {
		return;
	}

	map = new google.maps.Map(document.getElementById('store-map-canvas'), {
		zoom: 11,
		center: {lat: parseFloat($(stores[0]).data('latitude')), lng: parseFloat($(stores[0]).data('longitude'))},
		scrollwheel: false
	});

	// Loop through each stores
	stores.each(function() {
		// Variable setup
		var that = this,
				storeMarker,
				image = $(this).data('map-marker'),
				myLatLng = new google.maps.LatLng($(this).data('latitude'), $(this).data('longitude'));

		// Extends the bounds of the map to the Lat/Long position
		bounds.extend(myLatLng);

		// Creates a marker for this store
		storeMarker = new google.maps.Marker({
			title: unescape($(this).data('store-name')),
			position: myLatLng,
			map: map,
			icon: image
		});

		// Adds the marker to the map
		storeMarker.setMap(map);

		// Stores the markers in an array for easy access to all of them
		storeMarkers.push(storeMarker);

		// Creates the html for the info window
		var infoWindow = new google.maps.InfoWindow({
		    content: '<span class=\"store-marker\"> <b>' + unescape($(this).data('store-name')) +'</b><br/>' + $(this).data('address1') +'<br/>' + $(this).data('citystatezip') +'<br/>' + $(this).data('phone') + '<br/><a href=\"#store-' + $(this).data('store-id') + '\" class=\"jump-to-store\">Get Directions</a></span>'
		});

		// Store the window so we can close it later
		infoWindows.push(infoWindow);

		// Adds a click event for the markers.
		google.maps.event.addListener(storeMarker, 'click', function() {
			var id = $(that).data('store-id');
			var store = $('#store-' + id);
			infoWindows.forEach(function(element, index){
				infoWindows[index].close();
			});

			// Displays the info window for the marker
			infoWindow.open(map, storeMarker);

			// Allows the user to jump directly to the store detail
			$('#map-wrapper .jump-to-store').on('click', function (e) {
				e.preventDefault();
				store.css('backgroundColor', '#dc5f27');
				store.animate({backgroundColor: 'transparent'}, 500);
				$(document).scrollTop( $('#store-' + id).offset().top );
			});
		});
	});

	// Fits the map to include all store markers
	if (stores.length > 1)
	{
		map.fitBounds(bounds);
	} else {
		// Zooms out a bit if there's only one store
		map.setZoom(11);
	}

	google.maps.event.addDomListener(window, 'resize', function() {
		google.maps.event.trigger(map, 'resize');
		map.fitBounds(bounds);

		if (stores.length === 1)
		{
			map.setZoom(11);
		}
	});
}

/**
 * @private
 * @function
 * @description Sets up the Google Map displayed on the page.
 */
function setupGoogleMaps() {
	// Initialize and center the map on the user's locatoin
	var latitude = User.latitude,
			longitude = User.longitude,
			address = User.storePostalCode || User.postalCode,
			geocoder = new google.maps.Geocoder(),
			patt = new RegExp("^[0-9]{5}(?:-[0-9]{4})?$");;

	// If the postal code was not passed, we'll try to gather it from the address
	if (!patt.test(address) || User.address) {
		geocoder.geocode( { 'address': User.address}, function(results, status) {
			if (status === google.maps.GeocoderStatus.OK) {
				for( var i = 0; i < results[0].address_components.length; i++){
					if (results[0].address_components[i].types[0] == "postal_code") {
						address = results[0].address_components[i].long_name;
					}
				}

				searchStores('postalcode=' + address, addFoundStoreMarkers);
			}
		});
	}

	function initialize() {
		var defaultSearchRadius = SitePreferences.DEFAULT_SEARCH_RADIUS;
		map = new google.maps.Map(document.getElementById('store-map-canvas'), {
			zoom: 11,
			center: {lat: latitude, lng: longitude},
			scrollwheel: false
		});

		if ( address !== '') {
			// Set form fields
			$('.postal-code').val(address);

			if (User.storeMaxDistance !== '') {
				$('.max-distance option[value="' + User.storeMaxDistance + '"]').val('selected', 'selected');
			} else if(defaultSearchRadius !== ''){
				$('.max-distance option').removeAttr('selected');
				$('.max-distance option[value="' + Number(defaultSearchRadius).toFixed(1) + '"]').attr('selected', 'selected');
				//$(".select-custom").selecter("update");
			}

			// Retrieve the Lat/Lng for the zipcode
			geocoder.geocode( { 'address': address}, function(results, status) {
				if (status === google.maps.GeocoderStatus.OK) {
					map.setCenter(results[0].geometry.location);
					searchStores('radius=' + defaultSearchRadius + '&postalcode=' + address, addFoundStoreMarkers);
				}
			});
		} else {
			// If we don't know where to center the map, default current Location
			//map.setCenter(new google.maps.LatLng(40.1836788, -111.6245684));
			map.setCenter(new google.maps.LatLng(latitude, longitude));
			$('.results-header, .results-body').hide();
		}
	}

	google.maps.event.addDomListener(window, 'load', initialize);
	google.maps.event.addDomListener(window, 'resize', function() {
		google.maps.event.trigger(map, 'resize');
		map.panTo(new google.maps.LatLng(latitude,longitude));
	});
}

/**
 * @private
 * @function
 * @description Sets up the Google Map displayed on the page.
 */
function setupDetailsMaps() {
	// Initialize and center the map on the user's locatoin
	var latitude,
			longitude,
			myLatLng,
			storeName,
			image;

	storeName = $('.store-address').data('store-name');
	latitude = $('.store-address').data('latitude') || User.latitude;
	longitude = $('.store-address').data('longitude') || User.longitude;
	//myLatLng = new google.maps.LatLng(40.1836788, -111.6245684);
	myLatLng = new google.maps.LatLng(latitude, longitude);
	image = $('.store-address').data('map-marker');

	function initialize() {
		storeMap = new google.maps.Map(document.getElementById('store-map-canvas'), {
			zoom: 11,
			center: {lat: latitude, lng: longitude},
			scrollwheel: false
		});

		// Creates a marker for this store
		var storeMarker = new google.maps.Marker({
			title: unescape(storeName),
			position: myLatLng,
			map: storeMap,
			icon: image
		});

		// Adds the marker to the map
		storeMarker.setMap(storeMap);
		storeMap.setCenter(new google.maps.LatLng(latitude, longitude));
	}

	google.maps.event.addDomListener(window, 'load', initialize);
	google.maps.event.addDomListener(window, 'resize', function() {
		google.maps.event.trigger(map, 'resize');
		map.panTo(new google.maps.LatLng(latitude,longitude));
	});
}

/**
 * @private
 * @function
 * @description Performs the setup for the store search
 */
function setupStoreSearch() {
	$('.store-search button').on('click', function (e) {
		e.preventDefault();
		var event_trigger = $(this).attr('name') + '=' + $(this).attr('value');
		// Gather data
		var data = $(this).closest('form').serialize() + '&' + event_trigger;


		// Perform search
		if ( $('input.store-search').val() != '' ) {
			searchStores(data);
		}
	});
	$(".use-my-location-finder").on('click', function(e){
		e.preventDefault();
		var postcode = $(this).data("postalcode");
		$('.store-search input[name*=postalCode]').val(postcode);
		//$('.store-search button').click();
		searchStores('usemylocation');
	});
}

/**
 * @private
 * @function
 * @description Searches for stores
 */
function searchStores(data, callback) {
	// Retrieve results

	if(data == 'usemylocation') {
		var searchurl =  Urls.usecurrentlocation;
	} else {
		var searchurl =  Urls.storeFinder;
	}

	console.log(searchurl);
	$.ajax({
		url : searchurl,
		data : data,
		type : 'post',
		success : function (result) {
			// Clear old markers
			storeMarkers.forEach(function(element, index, array) {
				storeMarkers[index].setMap(null);
			});

			// Clear old stores results
			$('.stores .store').remove();

			// Add the new-found stores
			$('.stores .container').html(result);

			var stores = $('.stores .store');
			if (stores.length > 0) {
				var locationText = '<span class="locations">{0}</span> {1} <span class="locations-detail">within <span class="miles">{2}</span> miles of <span class="postal-code">{3}</span></span>';
				locationText = locationText.replace('{0}', stores.length);

				if (stores.length > 1) {
					locationText = locationText.replace('{1}', "Locations");
				} else {
					locationText = locationText.replace('{1}', "Locations");
				}

				if (stores.length > 3) {
					$('.pt_store-locator #secondary .left-map-pane').css({
						"overflow-y": "scroll",
						"height": "740px"
					});
				}

				locationText = locationText.replace('{2}', parseInt($('.store-search input[name*=maxdistance]:checked').val()));
				locationText = locationText.replace('{3}', $('.store-search input[name*=postalCode]').val());

				$('.results-header, .results-body').show();
				$(".results-header h5").show().html(locationText);

				// Update markers
				addFoundStoreMarkers();
			} else {
				//$('.search-result-message').show().html('No results found').delay(2000).fadeOut();
				$('.left-map-pane .results-header h5').show().html('No results found').delay(2000).fadeOut();
				$('.results-body').hide();
			}


		},
		error : function () {
			console.log('An error occurred while performing the store search.');
		},
		complete : function () {
			if (typeof(callback) === 'function') {
				callback();
			}
		}
	});
}

/**
 * @private
 * @function
 * @description Initializes events for the Store Locator
 */
function initializeEvents() {
	$(document).ready(function() {
		setupGoogleMaps();
		setupDetailsMaps();
		if ($('.store-search input[name*=maxdistance]:checked').length == 0) {
			$('.store-search input[name*=maxdistance]:first').attr('checked','checked');
			$('.store-search input[name*=maxdistance]:first').click();
		}

		$('.store-details-link').on('click', function (e) {
	        e.preventDefault();
	        dialog.open({
	            url: $(e.target).attr('href')
	        });
	    });

		// Simple Expand
		$('.expand-container .expand-title').on('click', function(event) {
			event.preventDefault();

			$(this)
				.toggleClass('active');

			$(this)
				.next('.expand-content')
				.stop()
				.slideToggle();
		});

		$('label[for*=dwfrm_storelocator_maxdistance-]').on('click', function(){
			//$(this).parents('.field-wrapper').find('input[type=radio]').each(function(){ $(this).attr('checked',false) });
			$(this).siblings('input.input-radio').click();
		});

	});

	setupStoreSearch();
}

exports.init = function () {
	//initializeEvents();
};
