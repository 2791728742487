'use strict';

var dialog = require('../../dialog');
var productStoreInventory = require('../../storeinventory/product');
//var tooltip = require('../../tooltip');
var util = require('../../util');
var addToCart = require('../../../../../app_storefront_core/cartridge/js/pages/product/addToCart');
var availability = require('./availability');
var image = require('../../../../../app_storefront_core/cartridge/js/pages/product/image');
var video = require('../../../../../app_storefront_core/cartridge/js/pages/product/video');
//var productNav = require('./productNav');
var productSet = require('./productSet');
var variant = require('../../../../../app_storefront_core/cartridge/js/pages/product/variant');
var sameDayShippingCountdown = require('../../../../../app_storefront_core/cartridge/js/components/SameDayShippingCountdown');
var relevantProductRecommendations = require('../../../../../app_storefront_core/cartridge/js/components/RelevantProductRecommendations');

/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */
function initializeDom(options) {
    //productNav();
    //tooltip.init();
    variant.initializeDom();
    
    var $sameDayShippingCountdownContainer = $('.same-day-shipping-countdown');
    if ($sameDayShippingCountdownContainer.length > 0) {
    	sameDayShippingCountdown.initialize($sameDayShippingCountdownContainer, options.SameDayShipping);
    }
}

/**
 * @description Initialize event handlers on product detail page
 */
function initializeEvents() {
    var $pdpMain = $('#pdpMain');

    addToCart();
    availability();
    variant.initializeEvents();
    image();
    video();
    productSet();
    if (SitePreferences.STORE_PICKUP) {
        productStoreInventory.init();
    }

    // Add to Wishlist and Add to Gift Registry links behaviors
    $pdpMain.on('click', '[data-action="gift-registry"]', function () {
        var data = util.getQueryStringParams($('.pdpForm').serialize());
        if (data.cartAction) {
            delete data.cartAction;
        }
        var url = util.appendParamsToUrl(this.href, data);
        this.setAttribute('href', url);
    });

    // product options
    $pdpMain.on('change', '.product-options select', function () {
        var salesPrice = $pdpMain.find('.product-add-to-cart .price-sales');
        var selectedItem = $(this).children().filter(':selected').first();
        salesPrice.text(selectedItem.data('combined'));
    });

    // prevent default behavior of thumbnail link and add this Button
    $pdpMain.on('click', '.thumbnail-link, .unselectable a', function (e) {
        e.preventDefault();
    });

    $pdpMain.on('click', 'a.size-chart-link', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href')
        });
    });
    
    // Technology Features Tooltip
    // $('.list-product-info figure img').each(function() {
    // 	var $img = $(this),
    // 		$target = $img.parents('li').first().find('.tooltip-content img');
    	
    // 	$target.attr("src", $img.data('hover')).attr("alt", $img.attr("alt"));
    // });
    
    // $('.list-product-info').tooltip({
    //     items: 'li',
    //     tooltipClass: 'techfeature-tooltip',
    //     track: true,
    //     content: function () {
    //         return $(this).find('.tooltip-content').html();
    //     }
    // });
    
	$(document).on('click','.searchagain-btn',function() {
		$('#dialog-container .findStore-result').hide();
		$('#dialog-container .ui-dialog-footer').show();
		$('.ui-dialog').css({'height' : '230px !important','top':'260px !important'});
	});
	$(document).on('click','.find-store-btn',function() {
		dialog.open({
			html:($('.findStoreDialog').html()).replace(/select-holder/g,''),
			callback: function() {
				$(document).on('click','.findstore-formContainer .findStore-btn',function() {
					var zipcode    = $('#dialog-container input.zipcode').val(),
						radius     = $('#dialog-container select.radius').val(),
						postalcode = $('#dialog-container .postalcode select').val(),
						pid        = $('#dialog-container #pid').val(),
						values 	   = {
										pid : pid,
										zipCode : zipcode,
										radius : radius,
										postalcode : postalcode
										};
					
					$.ajax({
						url: Urls.storeavailability,
						type: "GET",
						data: values ,
						success: function (response) {
							$('#dialog-container .findStore-result').show();
							$('#dialog-container .ui-dialog-footer').hide();
							$('#dialog-container .findStore-result').html(response).text();
							
						},
						error: function(jqXHR, textStatus, errorThrown) {
							console.log(textStatus, errorThrown);
						}
					});
					
				});
			},
			options: {
				title: Resources.FINDSTORE_TITLE
			},
		});
		return false;
	});
    
    $(window).on('load', function() {
    	if ($(".product-review #BVRRSummaryContainer .bv-rating-ratio-number .bv-histogram-target").length) {
    		var pdpReviewMain = $(".product-review #BVRRSummaryContainer .bv-rating-ratio-number .bv-histogram-target");
    		var rtValue = $(".product-review #BVRRSummaryContainer .bv-rating-ratio-number span[itemprop=ratingValue]").html()
    		$(".product-review #BVRRSummaryContainer .bv-rating-ratio-number").prepend( "<div id='tmpPDPRatingValue'>"+rtValue+"</div>" );
    		pdpReviewMain.attr("style","display:none !important");
    		$("#tmpPDPRatingValue,.product-review #BVRRSummaryContainer .bv-rating-ratio-count").on('click', function(){
    			location.href = location.pathname + '#BVRRContainer';
    		});
    	}
    	
    	$(document).trigger('update-review-text');
    	
    	$("div[id^=BVRRSummaryContainer]").each(function () {
    	    if ($(this).find(".bv-rating-stars-on").width() > 0) { } else { $(this).find(".bv-stars-container").attr("style", "display:none !important"); }
    	});
    });
    
    $(document).on('update-review-text', function(){
    	if ($(".bv-secondary-rating-summary .bv-secondary-rating-summary-stars .bv-rating-ratio").length) {
    		var htmlContent = $(".bv-secondary-rating-summary .bv-secondary-rating-summary-stars .bv-rating-ratio").html();
    		$(".product-reviews #BVRRSummaryContainer").html(htmlContent);
    		if ($("#BVRRSummaryContainer dd.bv-rating-ratio-count span.bv-rating-ratio-count span").length) {
    			var reviewCountContentBlock = $("#BVRRSummaryContainer dd.bv-rating-ratio-count span.bv-rating-ratio-count span");
    			var reviewCountContent = reviewCountContentBlock.html();
    			if (reviewCountContent.indexOf(Resources.REVIEWS) < 0) {
    				reviewCountContent = reviewCountContent + ' ' + Resources.REVIEWS;
    				reviewCountContentBlock.html(reviewCountContent);
    			}
    		}
    	}
    });
	
    var attemptsToChangeText = 0;
    var addTextReviewsToCountReviews = setInterval(function(){
    	attemptsToChangeText++;
    	$('div[id^=BVRRInlineRating], div[class^=BVRRInlineRating]').each(function(){
    		var $countReviews = $(this).find('span.bv-rating-label');
    		if ($countReviews.length) {
    			var text = $countReviews.html();
        		if (text.indexOf(Resources.REVIEWS) < 0) {
        			$countReviews.html(text.replace(')', ' ' + Resources.REVIEWS + ')'));
        			clearInterval(addTextReviewsToCountReviews);
        		}
    		}
    	});
    	$('div[class^=BVRRInlineRating]').each(function(){
            if ( !$(this).hasAttr('id') ) {
                var classId = $(this).attr('class');
                var _htm = jQuery("div[id='"+classId+"']").html();
                $(this).html(_htm);
            }
        });

    	if (attemptsToChangeText > 10) {
    		clearInterval(addTextReviewsToCountReviews);
    	}
    }, 500);
}

var product = {
    initializeEvents: initializeEvents,
    init: function (options) {
		if (options && options.RelevantProductRecommendations) {
			relevantProductRecommendations.initialize(options.RelevantProductRecommendations);
		}
    	
		variant.initialize(options);
        initializeDom(options);
        initializeEvents(options);
    }
};

module.exports = product;
