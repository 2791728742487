'use strict';

var AddressAutocompleteDialog;
var AddressVerificationDialog;
var AddressVerificationHelper = require('../../../../bc_sheplersbootbarn_core/cartridge/js/AddressVerification/AddressVerificationHelper');
var giftcert = require('../giftcert');
var util = require('../util');
var dialog = require('../dialog');
var page = require('../page');
var validator = require('../validator');
var	orderStatusForm = require('../../../../app_storefront_core/cartridge/js/components/OrderStatusForm');

var PaymentMethodConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/PaymentInstrumentConstants').PaymentMethods;
var SiteConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');

var dataBuffer = {};
var options = {};
var loaded = {};

/**
 * @function
 * @description Initializes the events on the address form (apply, cancel, delete)
 * @param {Element} form The form which will be initialized
 */
 function initializeAddressForm() {

	console.log('initializeAddressForm');

    var $addressEditForm = $('#edit-address-form');
	$addressEditForm.on('click', '.submit-shipping', function (e) {
        e.preventDefault();

		console.log('submit-shipping');

		var addressType = $addressEditForm.attr('address-type') ? $addressEditForm.attr('address-type') : 'saved';
		var addressDeliverable = function () {
			$addressEditForm.submit();
		}
		var addressNotDeliverable = function (addressVerifyData) {
			AddressVerificationDialog.initialize(options, addressType, addressVerifyData);			
		}
		var country = $addressEditForm.find('[id*="_country"]').val();
		if (options && options.Lob && options.Lob.AddressVerification.Enabled && country.toUpperCase() == 'US' && $addressEditForm.find('.submit-shipping').attr('verified') != 'true') {
			AddressVerificationHelper.VerifyAddress($addressEditForm, options).then(addressDeliverable, addressNotDeliverable);
		} else {
			addressDeliverable();
		}
    });
	
	if (options && options.Lob && options.Lob.AddressAutocomplete.Enabled) {
		$(document).on('keyup.AddressAutocomplete', '#edit-address-form input[type="text"]', function (event) {
			var $this = $(this);
			var formValidator = $addressEditForm.validate();

			if (event.keyCode == 8 || event.keyCode == 46) {
				$this.parent().find('.address-autocomplete-dialog').remove();
			} else if (!formValidator.checkForm() && ($this.attr('id').indexOf('_address1') >= 0 || $this.attr('id').indexOf('_address2') >= 0 || $this.attr('id').indexOf('_city') >= 0 || $this.attr('id').indexOf('_postal') >= 0)) {
				var addressAutocompleteHandler = function (addressAutocompleteData) {
					AddressAutocompleteDialog.initialize(options, addressAutocompleteData);
				}
			
				AddressVerificationHelper.AutocompleteAddress($addressEditForm, $this, options).then(addressAutocompleteHandler);
			} else {
				$this.parent().find('.address-autocomplete-dialog').remove();
			}
		});
	}

	if (options && options.Lob && options.Lob.AddressVerification.Enabled) {
		$(document).on('SetAddressFromAddressVerification', function (event, addressData) {
			AddressVerificationHelper.SetAddressFromAddressVerificationDialog($addressEditForm, addressData);
			$addressEditForm.submit();
		});
	}

    validator.init();
}
/**
 * @private
 * @function
 * @description Toggles the list of Orders
 */
function toggleFullOrder () {
    $('.order-items')
        .find('li.hidden:first')
        .prev('li')
        .append('<a class="toggle">View All</a>')
        .children('.toggle')
        .click(function () {
            $(this).parent().siblings('li.hidden').show();
            $(this).remove();
        });
}
/**
 * @private
 * @function
 * @description Binds the events on the address form (edit, create, delete)
 */
function initAddressEvents() {
    $(document).ready(function(){
    	if ($('.address-field-container form select').length) {
        	$('.address-field-container form select').each(function(){
        		$(this).selectmenu({});
        	});
        }
    	

    	$("#dwfrm_profile_customer_berewarded").on("click",function(){
        	if ( $(this).is(':checked') ) {
        		$('.rewardsNumberContainer').slideDown();
        	} else {
        		$('.rewardsNumberContainer').slideUp();
        	}
    	});

		if ($('#edit-address-form').length > 0) {
			initializeAddressForm();
		}
    });

	$(window).on('load', function() {
		$('#primary #edit-address-form input[name="dwfrm_profile_address_remove"]').off('click');
		$('#primary #edit-address-form input[name="dwfrm_profile_address_remove"]').on('click', function(e) {
			e.preventDefault();
			var $form = $(this).parents('form');
			if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
				var url = util.appendParamsToUrl(Urls.deleteAddress, {
					AddressID: $form.find('#addressid').val(),
					format: 'ajax'
				});
				$.ajax({
					url: url,
					method: 'POST',
					dataType: 'json'
				}).done(function (data) {
					if (data.status.toLowerCase() === 'ok') {
						dialog.close();
						var qParams = util.getQueryStringParams(window.location.search.substr(1));
						if(qParams.cid !== null && qParams.cid == 'billing') {
						location.href = Urls.paymentsList;
						} else {
							location.href = Urls.addressesList;
						}
					} else if (data.message.length > 0) {
						window.alert(data.message);
						return false;
					} else {
						dialog.close();
						location.href = Urls.addressesList;
					}
				});
			}
		});
	});
}
/**
 * @private
 * @function
 * @description Binds the events of the payment methods list (delete card)
 */
function initPaymentEvents() {
	var $creditCardTypeSelect = $('select[id*="creditcard_type"]');
	
	// Remove dialog payment add/edit implementation to use page redirect instead
    /*$('.add-card').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href')
        });
    });*/

	$creditCardTypeSelect.off('change.CreditCardTypeDetection').on('change.CreditCardTypeDetection', function (e) {
		var $this = $(this);
		var paymentInstrumentType = $this.val();
		var $allianceDataFieldDelta = $('input[name*="_owner"], select[name*="_month"], select[name*="_year"], label.expiration-date');
		var $allianceDataDisplayDelta = $('label.expiration-date');
		
	    switch (paymentInstrumentType) {
			case PaymentMethodConstants.AllianceData:
				$allianceDataFieldDelta.removeClass('required').closest('.formfield').hide();
				$allianceDataDisplayDelta.hide();
				break;
				
			default:
				$allianceDataFieldDelta.addClass('required').closest('.formfield').show();
				$allianceDataDisplayDelta.show();
				break;
		}
	});
	
	$('.cc-number-container input[type="text"]').off('keyup.CreditCardTypeDetection').on('keyup.CreditCardTypeDetection', function (e) {
		var selectedCreditCardType = util.getCreditCardType($(this).val());
		
		if (selectedCreditCardType) {
			$creditCardTypeSelect.val(selectedCreditCardType.PaymentCardDisplayKey);
		} else {
			$creditCardTypeSelect.val('');
		}
		
		$creditCardTypeSelect.trigger('change.CreditCardTypeDetection');
	});
    
    $creditCardTypeSelect.trigger('change.CreditCardTypeDetection');

    var paymentList = $('.payment-list');
    if (paymentList.length === 0) { return; }

    $('form[name="payment-remove"] button[type="submit"]').on('click', function (e) {
        e.preventDefault();
        
        // override form submission in order to prevent refresh issues
        var $button = $(e.target);
        
        // confirm delete
        if ($button.hasClass('delete')) {
        	if (!window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_CREDITCARD))) {
        		return false;
        	}
        }
        
        var $form = $(this).closest('form'); 
        
        $('<input/>').attr({
            type: 'hidden',
            name: $button.attr('name'),
            value: $button.attr('value') || 'delete card'
        }).appendTo($form);
        var data = $form.serialize();
        $.ajax({
            type: 'POST',
            url: $form.attr('action'),
            data: data
        })
        .done(function () {
            page.redirect(Urls.paymentsList);
        });
    });
}
/**
 * @private
 * @function
 * @description init events for the loginPage
 */
function initLoginPage() {
    //o-auth binding for which icon is clicked
    $('.oAuthIcon').bind('click', function () {
        $('#OAuthProvider').val(this.id);
    });

    //toggle the value of the rememberme checkbox
    $('.login-rememberme input').on('change', function () {
        if ($(this).prop('checked')) {
            $('#rememberme').val('true');
        } else {
            $('#rememberme').val('false');
        }
    });
    $('.btn-lookup-order').on('click', function (e) {
        e.preventDefault();
        $('div.check-order-content').slideDown();
        $(this).slideUp();
    });
    $('#password-reset-rewards').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            target : $(".password-reset-dialog-container"),
            options: {
            	dialogClass: 'select-rewards-number-popup',
            	width: 385,
                open: function () {
                    validator.init();
                    var $requestPasswordForm = $('[name$="_requestpassword"]'),
                        $submit = $requestPasswordForm.find('[name$="_requestpassword_send"]'),
                    	$resetPasswordForm = $('[name$="_resetpassword"]');
                    $($submit).on('click', function (e) {
                        if (!$requestPasswordForm.valid()) {
                            return;
                        }
                    });
                    if ($resetPasswordForm.length) {
                    	$resetPasswordForm.find("button[type=submit]").on('click', function (e) {
                    		e.preventDefault();
                    		var nameAttr = $(this).attr('name');
                    		dialog.submit(nameAttr);
                    	});
                    }
                    $(".reset-password-heading-close-btn").on('click',function(){
                        $("button.ui-dialog-titlebar-close").click();
                    });
                    execBarCodeGeneration();
                }
            }
        });
    });
    $("#optin-privacy-policy").on('click', function(e){ e.preventDefault(); location.href=Urls.privacyPage; })
    $("#reward-learn-more").on('click', function(e){ e.preventDefault(); location.href=Urls.learnFAQ; })
    if ($('#password-reset-rewards').length) {
    	var ct = 0;
    	while(ct < 2) {
    		ct++;
    		$('#password-reset-rewards').click();
    	}
    }
}
/**
 * @private
 * @function
 * @description Binds the events of the order, address and payment pages
 */
function initializeEvents() {
    toggleFullOrder();
    initAddressEvents();
    initPaymentEvents();
    initLoginPage();
    util.limitInputToLength('#CreditCardForm .newcreditcardnumber input.input-text', 16);
    
    $('button.ui-dialog-titlebar-close').on('touchstart', function() {
        $("button.ui-dialog-titlebar-close").click();
    });

    var blurFields = "#dwfrm_profile_customer_email, #dwfrm_profile_customer_emailconfirm, input[id*=dwfrm_profile_login_password_], input[id*=dwfrm_profile_login_passwordconfirm_]";
    $(blurFields).blur(function(){
        if ( $(this).val() == '' ) {
            if ($(this).parents('.form-row').find( '.form-caption.error-message' ).length) {
                $(this).parents('.form-row').find( '.form-caption.error-message' ).html('');
            }
        }
    });
}

function execBarCodeGeneration() {
    if ($('.rewardsregistrationconfirmation').length) {
        var barcodeNumber = ""+$('.rewardsregistrationconfirmation').find("[data-rewardsnumber]").data('rewardsnumber');
        var barcodeType = 'code128';
        
        $("#barcodeTarget").barcode(barcodeNumber, barcodeType, {
            barHeight:'75',
            barWidth:'2',
            fontSize:'14',
            fontWeight:'bold',
            output:'bmp'
        });
    }
}

function initRewardsPage() {
	execBarCodeGeneration();
}

$(window).on("message", function(e) {
    var data = e.originalEvent.data;
    if(data == "error"){
    	$(".menu-utility-user").addClass("loginError");
    }
});

function renderOptions(optionParent, optionsStoreId) {
	var options = '';
	var statesList = JSON.parse($(optionsStoreId + ' option:selected').data('options').replace(/\'/g, '\"'));
	
	statesList.forEach(function(state){
		options += '<option class="select-option" label="'+state.label+'" value="'+state.code+'">'+state.label+'</option>';
	});
	$(optionParent).empty().append(options);
	
	if (statesList.length <= 1) {
		$(optionParent).parent().parent().parent().hide();
		$(optionParent + '_sbm').val($(optionParent).val()).attr('disabled', false);
	} else {
		$(optionParent).parent().parent().parent().show();
		$(optionParent + '_sbm').attr('disabled', true);
	}
}

function initRewardRegisterForm() {
	$(document).ready(function() {
		var $rewardRegisterForm = $('#dwfrm_rewardsregistration');
		if ($rewardRegisterForm.length > 0) {
			$rewardRegisterForm.find('#dwfrm_rewardsregistration_rewardscustomer_phone').rules('add', {
				phone: true
			});
			$rewardRegisterForm.find('#dwfrm_rewardsregistration_rewardscustomeraddress_zipcode').rules('add', {
				postal: true
			});
			$rewardRegisterForm.find('#dwfrm_rewardsregistration_rewardscustomer_email').rules('add', {
				requireEmailExtension: true
			});
			$rewardRegisterForm.find('#dwfrm_rewardsregistration_rewardscustomer_emailconfirm').rules('add', {
				requireEmailExtension: true,
				equalTo: '#dwfrm_rewardsregistration_rewardscustomer_email'
			});

			$rewardRegisterForm.on('keyup', '#dwfrm_rewardsregistration_rewardscustomer_email', function(event) {
				var serverErrorMessage = $(event.target).closest('.form-row').find('.error-message');
				if (serverErrorMessage.length > 0) {
					serverErrorMessage.remove();
				}
			});
		}
	});
}

function initRewardCheckForm() {
	$(document).ready(function() {
		var $rewardCheckForm = $('#rewardsCheckBalance');
		if ($rewardCheckForm.length > 0) {
			$rewardCheckForm.find('#fieldRewardsNumber').rules('add', {
				require_from_group: [1, ".index-group"],
				positivenumber: true
			});
			$rewardCheckForm.find('#fieldRewardsEmail').rules('add', {
				requireEmailExtension: true,
				require_from_group: [1, ".index-group"]
			});
			$rewardCheckForm.find('#fieldRewardsZipcode').rules('add', {
				postal: true
			});

			$rewardCheckForm.find('input[type="submit"]').on('click', function(event) {
				event.preventDefault();
				var $resultsContainer = $('.rewards-results');

				$resultsContainer.html('');

				if (!$rewardCheckForm.valid()) {
					return false;
				}

				$.ajax({
					url : Urls.getRewardInfoForRegistered,
					dataType : 'JSON',
					method: 'POST',
					data : {
						'rewardsNumber': $rewardCheckForm.find('#fieldRewardsNumber').val(),
						'email': $rewardCheckForm.find('#fieldRewardsEmail').val(),
						'zipcode': $rewardCheckForm.find('#fieldRewardsZipcode').val()
					},
					success : function(data) {
						if ('error' in data && data['error']) {
							$resultsContainer.append('<p class="error-block">'+data['message']+'</p>');
						} else {
							renderRewardCheckResults(data, $resultsContainer);
							$('form#rewardsCheckBalance').hide();
						}
					},
					complete: function(data, status, xhr) {
						if (data.status == 302 && data.responseText) {
							location.href = data.responseText;
						}
					}
				});
			});
		}
	});
}

function renderRewardCheckResults(data, $resultsContainer) {
	$resultsContainer.append('<div class="rewardsLookup"><b>' + Resources.LABEL_REWARDSNUMBER + ':</b> ' + '<span>' + data.rewardsNumber + '</span></div><div class="rewardsLookup"><b>' + Resources.LABEL_POINTBALANCE + ':</b> ' + '<span>' + data.pointBalance + '</span></div><div class="rewardsLookup"><b>' + Resources.LABEL_LASTACTIVITY + ':</b> ' + '<span>' + data.dateLastActivity + '</span></div><div class="rewardsLookup"><b>' + Resources.LABEL_EARNEDLASTYEAR + ':</b> ' + '<span>' + data.pointsEarnedInLastYear + '</span></div>');

	if(data.creditAmount) {
		$resultsContainer.append('<div class="rewardsLookup availableRewardsCreditAlert"><b>' + Resources.LABEL_CREDITSAVAILABLE + ':</b> ' + '<span>$' + parseFloat(data.creditAmount).toFixed(2) + '</span></div><div class="rewardsLookup availableRewardsCreditAlert"><b>' + Resources.LABEL_CREDITSEXPIRATION + ':</b> ' + '<span>' + data.creditExpirationDate + '</span></div>');	
	}
	
	$resultsContainer.append('<form id="goToCredit" method="post" target="_blank" action="' + data.endpoint + '">'+
			'<input type="hidden" name="CustID" value="' + data.rewardsNumber + '"/>'+
			'<input type="hidden" name="Email" value="' + data.email + '"/>'+
			'<input type="hidden" name="FirstName" value="' + data.firstName + '"/>'+
			'<input type="hidden" name="LastName" value="' + data.lastName + '"/>'+
			'<input type="hidden" name="Address" value="' + data.address + '"/>'+
			'<input type="hidden" name="City" value="' + data.city + '"/>'+
			'<input type="hidden" name="State" value="' + data.state + '"/>'+
			'<input type="hidden" name="Zip" value="' + data.zipValue + '"/>'+
			'<input type="hidden" name="AuthCode" value="' + data.authCode + '"/>'+ 
			'<div  class="rewardsLookup formfield"><br><button class="btn btn-block btn-medium" type="submit">Apply for BootBarn Credit Card</button></div>'+
	'</form>');
}

function initAddressFormEvents() {
	$(document).ready(function(){
		$(document).on('change', '#dwfrm_profile_address_country', function(){
			renderOptions('#dwfrm_profile_address_states_state', '#dwfrm_profile_address_country');
			var $initialState = $('#dwfrm_profile_address_states_state_sbm');
			if ($initialState.length > 0 && $initialState.val() != '') {
				$('#dwfrm_profile_address_states_state').val($initialState.val());
			}
		});
		
		var $form = $('#edit-address-form');
		if ( $form.find('#dwfrm_profile_address_country').attr('defaultvalue') != null && ( $form.find('#dwfrm_profile_address_country').val() == null || $form.find('#dwfrm_profile_address_country').val() == '' ) ) {
			var addressDefVal = $form.find('#dwfrm_profile_address_country').attr('defaultvalue');
			$form.find('#dwfrm_profile_address_country').val(addressDefVal);
			$form.find('#dwfrm_profile_address_country').trigger('change');
		}
		
		$('#dwfrm_profile_address_country').trigger('change');

		$(document).on('click', '.address-tile .address-delete', function() {
			if (!confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
				return false;
			}
		});
	});
}

module.exports = {
    init: function (optionsParameter) {
		options = optionsParameter;

		if (options && options.Lob && options.Lob.AddressVerification.Enabled) {
			AddressVerificationDialog = require('../../../../app_storefront_core/cartridge/js/Lob/AddressVerification/AddressVerificationDialog');
		}

		if (options && options.Lob && options.Lob.AddressAutocomplete.Enabled) {
			AddressAutocompleteDialog = require('../../../../app_storefront_core/cartridge/js/Lob/AddressAutocomplete/AddressAutocompleteDialog');
		}

        initializeEvents();
        giftcert.init();
        initAddressFormEvents();
        initRewardRegisterForm();
        initRewardCheckForm();
        orderStatusForm.initialize($('#dwfrm_ordertrack'));
    },
    initCartLogin: function () {
        initLoginPage();
        initRewardsPage();
    }
};
